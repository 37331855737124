/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import LogLayout from '../layout/LogLayout';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../firebase';
import { Link } from 'react-router-dom';

export default function Authentification() {
  const { signin } = useContext(AuthContext);
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [firebaseError, setFirebaseError] = useState('');
  const navigate = useNavigate();

  // const goToRegister = (e) => {
  //   e.preventDefault;
  //   navigate('/register');
  // };

  const login = async (e, cred) => {
    e.preventDefault();

    signin(cred.email, cred.password)
      .then(() => {
        cred.password === '123abcDEF'
          ? navigate(`/create-new-password`)
          : navigate(`/welcome`);
      })
      .catch((error) => {
        console.log('Error during log in');
        setFirebaseError(error.message);
      });
  };

  return (
    <>
      <LogLayout>
        <h1 className="text-[36px] text-greenBaaS text-bold mt-10 mb-10">
          Login
        </h1>
        <form
          onSubmit={(e) => login(e, credentials)}
          className="w-2/3 flex flex-col items-center h-full justify-between"
        >
          <div className="">
            <input
              name="email"
              placeholder="john.doe@example.com"
              autoComplete="off"
              type="text"
              onChange={(e) =>
                setCredentials({ ...credentials, email: e.target.value })
              }
              value={credentials.email}
              className=" bg-white w-full py-4 px-2 mb-8  border shadow-sm  rounded-xl"
            />

            <input
              name="email"
              placeholder="password"
              autoComplete="off"
              type="password"
              onChange={(e) =>
                setCredentials({ ...credentials, password: e.target.value })
              }
              value={credentials.password}
              className=" bg-white w-full py-4 px-2 mb-4  border shadow-sm  rounded-xl"
            />
            <p className="text-xs h-8 text-red-600">{firebaseError}</p>
          </div>
          <div className="flex-col flex items-center w-full">
            <button
              type="submit"
              className="bg-greenButtonBaaS text-white w-2/3 py-4 hover:bg-greenBaaS rounded-[100px] mb-2"
            >
              Se connecter
            </button>

            <Link to={`/forgotten`} className="text-black mb-8 text-sm">
              Mot de passe oublié?
            </Link>
            {/* <p
              onClick={goToRegister}
              className="cursor-pointer rounded-xl text-center text-black"
            >
              Créer son compte
            </p> */}
          </div>
        </form>
      </LogLayout>
    </>
  );
}
