import { app } from './index';
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  updatePassword,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';

import { USERS } from './users';

import { createContext, useState, useContext } from 'react';
import { useEffect } from 'react';

const AuthContext = createContext(undefined);

function AuthProvider({ children, ...props }) {
  const auth = getAuth(app);

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Register onAuthStateChangerd
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
      if (user !== null) {
        let userProfile = await USERS.loadUserProfile(user.uid);

        setCurrentUser({
          user: user,
          profile: userProfile,
        });
      } else {
        setCurrentUser(null);
      }

      // Stop loading
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const verify = async (user) => {
    return sendEmailVerification(user.user);
  };

  const createUserByAdmin = async (email, password) => {
    try {
      console.log(email, password);
      let creds = await createUserWithEmailAndPassword(auth, email, password);
      return creds;
    } catch (error) {
      console.log('Error during sign up', error);
      return false;
    }
  };

  const signup = async (email, password) => {
    try {
      let creds = await createUserWithEmailAndPassword(auth, email, password);
      await USERS.createCoach(creds.user);
      setLoading(true);
      return true;
    } catch (error) {
      console.log('Error during sign up', error);
      return false;
    }
  };

  const signin = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);

      // Start loading
      setLoading(true);
    } catch (error) {
      console.log('Error during sign in', error);
      throw error;
    }
  };

  const logout = async () => {
    return signOut(auth);
  };

  const changePassword = async (newPassword) => {
    try {
      await updatePassword(currentUser.user, newPassword);
      return true;
    } catch (error) {
      console.log('Update password failed', error);
      return false;
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      console.log('Error during password reset', error.message);
      return false;
    }
  };

  const verifyPasswordResetCodeHandler = async (actionCode) => {
    try {
      let email = await verifyPasswordResetCode(auth, actionCode);
      console.log('Password Reset Code Verification', email);
      return true;
    } catch (error) {
      console.log(
        'Error during password reset code verification',
        error.message
      );
      return false;
    }
  };

  const confirmPasswordResetHandler = async (actionCode, newPassword) => {
    try {
      let resp = await confirmPasswordReset(auth, actionCode, newPassword);
      console.log('Password Reset confirmation', resp);
      return true;
    } catch (error) {
      console.log('Error during password reset', error.message);
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        createUserByAdmin,
        signup,
        signin,
        logout,
        verify,
        changePassword,
        resetPassword,
        confirmPasswordResetHandler,
        verifyPasswordResetCodeHandler,
        updatePassword,
      }}
      {...props}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
}

const isUserConnected = () => {
  const auth = useContext(AuthContext);
  // console.log('Check user connected', auth.currentUser);
  return {
    ...auth,
    isAuthenticated: auth.currentUser !== null,
  };
};

export { AuthProvider, isUserConnected, AuthContext };
