import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();

export const updateSurvey = httpsCallable(functions, 'updateSurvey');
export const updateMapSurvey = httpsCallable(functions, 'updateMapSurvey');
export const getResponse = httpsCallable(functions, 'getResponse');
export const createSurvey = httpsCallable(functions, 'createSurvey');
export const lauchTeamCampaign = httpsCallable(functions, 'lauchTeamCampaign');
export const lauchOrgaCampaign = httpsCallable(functions, 'lauchOrgaCampaign');
export const generateMapCampaignsOrga = httpsCallable(
  functions,
  'generateMapCampaignsOrga'
);
export const finishAllCampaignsTeam = httpsCallable(
  functions,
  'finishAllCampaignsTeam'
);
export const finishAllCampaignsOrga = httpsCallable(
  functions,
  'finishAllCampaignsOrga'
);
export const finishOneSurvey = httpsCallable(functions, 'finishOneSurvey');
export const finishOneSurveyOrga = httpsCallable(
  functions,
  'finishOneSurveyOrga'
);
export const finishOneSurveyAndClose = httpsCallable(
  functions,
  'finishOneSurveyAndClose'
);
export const fetchUserData = httpsCallable(functions, 'fetchUserData');
export const createClient = httpsCallable(functions, 'createClient');
export const createUser = httpsCallable(functions, 'createUser');
export const updateUserPassword = httpsCallable(
  functions,
  'updateUserPassword'
);
