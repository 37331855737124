export const translationFr = {
  welcome: 'Missions de coaching',
  newMission: 'Nouvelle Mission',
  newTeam: 'nouvelle équipe',
  existingTeam: 'équipes existantes',
  missionName: 'Intitulé de la mission',
  newCampaign: 'Nouvelle Campaign',
  noMission: 'Aucune mission',
  status: 'Statut',
  details: 'Détails',
  unknowClient: 'Client inconnu',
  unknowMission: 'Mission inconnue',
  finishMission: 'Terminer la mission',
  noCampaignYet: 'Pas encore de campagne',
  addTeam: 'Ajouter une équipe',
  teamName: "Nom de l'équipe",
  memberEmail: 'Email du membre',
  findMember: 'Rechercher un membre',
  memberList: 'Liste des membres',
  selectTeam: ' Selectionner les équipes à ajouter',
  add: 'Ajouter',
  team: 'Équipe',
  collaborators: 'collaborateurs',
  modify: 'Modifier',
  delete: 'Supprimer',
  responses: 'Réponses',
  generateCard: 'Générer Carte',
  seeCard: 'Voir Carte',
  noCardAvailable: 'Carte non disponible',
  draft: 'En Préparation',
  inProgress: 'En Cours',
  ended: 'Terminée',
  launchCampaign: 'Lancer la campagne',
  stopCampaign: 'Terminer la campagne',
  deleteCampaign: 'Supprimer la campagne',
  campaignName: 'Intitulé de la campagne',
  create: 'Créer',
  welcomeTo: 'Bienvenue sur votre espace',
  surveys: 'Questionnaires',
  mySurveys: 'Mes Questionnaires',
  login: 'Se connecter',
  logout: 'Se deconnecter',
  concernedTeams: 'Équipe concernée',
  go: 'Répondre',
  waiting: 'En attente',
  answered: 'Répondu',
  noCard: 'Aucune carte générée',
  average: 'Moyenne',
  standardDeviations: 'Écart-Types',
  categories: 'Listes des catégories',
  emotion: 'Emotion',
  space: 'Espace',
  time: 'Temps',
  energy: 'Energie',
  meaning: 'Sens',
  organization: 'Organisation',
  visualization: "Visualisation de la PRO de l'équipe",
  visualizationOrga: 'Visualisation de la PRO Organisation',
  surveyFrom: 'Questionnaire du ',
  surveyClose: 'Questionnaire fermé',
  close: 'Fermer',
  yourAnswer:
    "Vos réponses sont attendues dans le cadre de l'accompagnement de ",
  toImprove: ' pour améliorer la performance relationnelle de votre équipe.',
  start: 'Démarrer',
  thanksTo: ' Merci pour votre participation',
  goBack: 'Revenir sur votre dashboard',
  theMethod: 'La Méthode P.R.O',
  text1:
    "Notre solution de diagnostic, la Performance Relationnelle Optimale, mesure objectivement l’état de la relation à travers 5 dimensions. En se focalisant sur les relations entre les équipes, départements ou des directions, vous visualisez  l’ensemble du tissu relationnel de votre organisation. Il devient alors possible d’intervenir / pour faire varier des paramètres dans la relation. Nos outils sont conçus pour permettre de visualiser les datas soutenant les relations de votre organisation. Il devient dès lors possible de décider quels accompagnements déclencher pour quels objectifs et surtout d'en mesurer les impacts au fur et à mesure. Parce qu’elle se concentre sur la performance de la relation, la P.R.O® se distingue des autres modèles orientés individus en donnant à l'organisation la capacité d'action indispensable à ses enjeux de transformation.",
  glocary: 'Glassaire',
  relationFabric: 'Le tissu relationnel',
  text2:
    'En fonction du taux de complétion des questionnaires, vous avez accès à la représentation du tissu relationnel de votre organisation. Chacune des unités relationnelles est représentée par un cercle identifié par son acronyme.',
  relation: 'La relation',
  text3:
    "Nous considérons la relation comme le sujet permettant d'améliorer de manière durable la performance des organisations. Plutôt que de nous centrer sur l'individu ou sur l'unité, nous favorisons l'observation de ce que produit l'interaction et ce de manière objective.Nos outils sont conçus pour permettre de visualiser les datas soutenant les relations de votre organisation. Il devient dès lors possible de décider quels accompagnements déclencher pour quels objectifs et surtout avec quel impact au fur et à mesure",
  passwordLenght: 'Le mot de passe doit comporter au moins 8 caractères',
  passwordUppercase:
    'Le mot de passe doit contenir au moins une lettre majuscule (A-Z)',
  passwordLowercase:
    'Le mot de passe doit contenir au moins une lettre minuscule (a-z)',
  passwordDigit: 'Le mot de passe doit contenir au moins un chiffre (0-9)',
  passwordSpecial:
    'Le mot de passe doit contenir au moins un caractère spécial (!@#$&*)',
  profile: "Profil de l'utilisateur",
  changePassword: 'Changer mot de passe',
  resettingPassword: 'Essayer de réinitialiser votre mot de passe',
  resetPassword: 'Réinitialiser mot de passe',
  currency: 'fois',
  words: 'Les mots les plus utilisés sont',
  happyPro: 'Bonne PRO',
  verticilleTeam: "l'équipe Verticille",
  welcomeToCollab1:
    "Bonjour et bienvenue sur votre espace P.R.O Vous trouverez ici l'ensemble des questionnaires relatifs aux équipes auxquelles vous appartenez. Pour mémoire, il va s'agir de donner votre estimation de la RELATION et non pas des personnes ou équipes avec lesquelles vous interagissez. ",
  welcomeToCollab2:
    "Une fois que tous les membres de l'équipe auront rempli le questionnaire, vous serez en mesure de voir la dataviz de l'équipe concernée. Celle-ci représente l'ensemble des relations mesurées de chacun d'entre vous à l'équipe. Nous partirons de cette représentation pour travailler ensemble sur l'évolution de ces relations afin de leur permettre de fonctionner au mieux en lien avec votre objectif commun. ",
  welcomeToAdmin1:
    "Bonjour et bienvenue sur votre espace P.R.O .Vous trouverez ici l'ensemble des questionnaires relatifs aux équipes de votre périmètre client. Pour mémoire, il va s'agir de donner l'estimation de la RELATION des personnes avec leurs équipes, et non pas de la mesure des personnes ou équipes concernées. ",

  welcomeToAdmin2:
    "Une fois que tous les membres de l'équipe auront rempli le questionnaire, vous serez en mesure de voir la dataviz de l'équipe concernée. Celle-ci représente l'ensemble des relations mesurées dans l'équipe considérée. Nous partirons de cette représentation pour travailler ensemble sur l'évolution de ces relations afin de leur permettre de fonctionner au mieux en lien avec votre objectif commun. ",
  welcomeToManagingPartner1:
    "Bonjour et bienvenue sur votre espace P.R.O .Vous trouverez ici l'ensemble des questionnaires relatifs aux équipes de votre périmètre client. Pour mémoire, il va s'agir de donner l'estimation de la RELATION des personnes avec leurs équipes, et non pas de la mesure des personnes ou équipes concernées. ",
  welcomeToManagingPartner2:
    "Une fois que tous les membres de l'équipe auront rempli le questionnaire, vous serez en mesure de voir la dataviz de l'équipe concernée. Celle-ci représente l'ensemble des relations mesurées dans l'équipe considérée. Nous partirons de cette représentation pour travailler ensemble sur l'évolution de ces relations afin de leur permettre de fonctionner au mieux en lien avec votre objectif commun. ",
  welcomeToCoach1:
    "Bonjour et bienvenue sur votre espace P.R.O. Vous trouverez ici l'ensemble des questionnaires relatifs aux clients que vous suivez. Pour mémoire, il va s'agir de donner l'estimation de la RELATION des personnes avec leurs équipes, et non pas de la mesure des personnes ou équipes concernées. ",
  welcomeToCoach2:
    "Une fois que tous les membres de l'équipe cliente auront rempli le questionnaire, vous serez en mesure de voir la dataviz de l'équipe concernée. Celle-ci représente l'ensemble des relations mesurées dans l'équipe considérée. Vous partirez de ces représentations pour travailler ensemble sur l'évolution de ces relations afin de leur permettre de fonctionner au mieux en lien avec votre objectif commun. ",
  seenByDefault: 'Voir par défaut',
  targetTeam: 'Équipe ciblée',
  minMax: 'Écarts aux pôles (min | max)',
  effort1: 'Je PEUX faire quelque chose pour la réussite du projet',
  effort2: 'Je DOIS faire quelque chose pour la réussite du projet',
  effort3: 'Je VEUX faire quelque chose pour la réussite du projet',
  efforts: 'Résistances',
};
