import { db } from './index';
import { v4 as uuidv4 } from 'uuid';
import {
  doc,
  getDocs,
  getDoc,
  updateDoc,
  arrayUnion,
  collection,
  arrayRemove,
  where,
  query,
  // addDoc,
} from 'firebase/firestore';

const CLIENTS = {
  getClient: async (id) => {
    const docRef = await getDoc(doc(db, 'clients', id));
    const clientTmp = docRef.data();
    clientTmp.id = docRef.id;
    return clientTmp;
  },
  getClients: async () => {
    const querySnapshot = await getDocs(collection(db, 'clients'));
    const clients = [];
    querySnapshot.forEach((doc) => {
      let new_client = doc.data();
      new_client.uid = doc.id;
      clients.push(new_client);
    });
    return clients;
  },

  getClientsByAdmin: async (adminId) => {
    const clientsTmp = [];
    const q = query(
      collection(db, 'clients'),
      where(`coach_uid`, '==', adminId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const clientTmp = doc.data();
      clientTmp.uid = doc.id;
      clientsTmp.push(clientTmp);
    });

    return clientsTmp;
  },

  createAdmin: async (id, userId) => {
    try {
      await updateDoc(doc(db, 'clients', id), {
        admin_uids: arrayUnion(userId),
        collab_uids: arrayUnion(userId),
      });
      return true;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  deleteAdmin: async (id, userId) => {
    try {
      await updateDoc(doc(db, 'clients', id), {
        admin_uids: arrayRemove(userId),
        collab_uids: arrayRemove(userId),
      });
      return true;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  createManagingPartner: async (id, userId) => {
    try {
      await updateDoc(doc(db, 'clients', id), {
        managing_partner_uids: arrayUnion(userId),
        collab_uids: arrayUnion(userId),
      });
      return true;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  deleteManagingPartner: async (id, userId) => {
    try {
      await updateDoc(doc(db, 'clients', id), {
        managing_partner_uids: arrayRemove(userId),
        collab_uids: arrayRemove(userId),
      });
      return true;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  addTeamToClient: async (id, body) => {
    const idTmp = uuidv4();
    const team = {
      id: idTmp,
      collaborators: body.collaborators,
      name: body.name,
    };
    try {
      await updateDoc(doc(db, 'clients', id), {
        teams: arrayUnion(team),
      });
      return idTmp;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  addCollabToClient: async (id, body) => {
    try {
      await updateDoc(doc(db, 'clients', id), {
        collab_uids: arrayUnion(body),
      });
      return;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  getCollaboratos: async (clientId) => {
    const docRef = await getDoc(doc(db, 'clients', clientId));
    return docRef.data().collab_uids;
  },

  RemoveTeamFromClient: async (id, body) => {
    try {
      await updateDoc(doc(db, 'clients', id), {
        teams: arrayRemove(body),
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

export { CLIENTS };
