import React, { useContext, useEffect, useState } from 'react';
import { TEAMS, AuthContext, db, SURVEYS } from '../../firebase';
import { MissionContext } from '../../hooks';
import { Link } from 'react-router-dom';
import {
  finishOneSurvey,
  finishOneSurveyAndClose,
} from '../../firebase/cloud-function';
import { doc, onSnapshot } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import DisplayCollaborators from '../modals/DisplayCollaborators';
import DisplayPicture from '../modals/DisplayPicture';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { uploadFiles } from '../../helpers/upload-file';
import { downloadCsv } from '../../helpers/download-csv';

export default function TeamRunningRow({ surveyId }) {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [team, setTeam] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [handleCollab, setHandleCollab] = useState(false);
  const [handlePicture, setHandlePicture] = useState(false);
  const [collabInfos, setCollabInfos] = useState(null);
  const [file, setFile] = useState(false);

  const { client } = useContext(MissionContext);
  const { currentUser } = useContext(AuthContext);

  useEffect(async () => {
    let surveyTmp;
    const unsub = onSnapshot(doc(db, 'surveys', surveyId), (doc) => {
      surveyTmp = doc.data();
      surveyTmp.uid = doc.id;
      setSurvey(surveyTmp);
    });

    return () => unsub();
  }, []);

  useEffect(async () => {
    const collabAvailable = [];
    if (survey) {
      let count = 0;
      const teamTmp = await TEAMS.getTeam(survey.team_uid);
      const responseUids = Object.values(survey.collab_uids);
      const collabUids = Object.keys(survey.collab_uids);
      for (let i = 0; i < responseUids.length; i++) {
        if (responseUids[i] !== null) {
          collabAvailable.push(collabUids[i]);
          count++;
        }
      }

      if (count !== 0) {
        const functions = getFunctions();
        const fetchUserData = httpsCallable(functions, 'fetchUserData');

        const response = await fetchUserData({
          requesterUID: currentUser.user.uid,
          requestedClientUID: client.id,
          requestedUserUIDS: collabAvailable,
        });

        setCollabInfos(response.data);
      }

      setTeam(teamTmp);
      setTotal(Object.values(survey.collab_uids).length);
      setCount(count);
    }
  }, [survey, client]);

  const generateMapHandler = async () => {
    await finishOneSurvey({ survey: survey });
  };

  const closeSurvey = async () => {
    await finishOneSurveyAndClose({ survey: survey });
  };

  const buildActions = () => {
    let viewMapButton;
    let generateMapButton;
    if (survey && survey.map) {
      viewMapButton = (
        <Link to={`/map_team/${survey.uid}`} className="button text-sm">
          {t('seeCard')}
        </Link>
      );
    } else {
      viewMapButton = <p className="text-black">{t('noCardAvailable')}</p>;
    }

    if (currentUser.profile.isCoach() && survey && survey.status === 1) {
      generateMapButton = (
        <>
          <button onClick={() => closeSurvey()} className="button text-sm">
            {t('close')}
          </button>
          <button
            onClick={() => generateMapHandler()}
            className="button text-sm"
          >
            {t('generateCard')}
          </button>
        </>
      );
    }

    return (
      <div className="border-black relative w-2/5 flex flex-wrap items-center justify-around">
        {generateMapButton}
        {viewMapButton}
        {survey && survey.map && currentUser.profile.isCoach() && (
          <>
            <button
              className="button"
              onClick={() => downloadCsv(survey, team.team)}
            >
              download CSV
            </button>
            <form
              onSubmit={(e) => formHandler(e, surveyId)}
              className="flex items-center"
            >
              <label
                onMouseEnter={
                  survey && survey.words_cloud_url
                    ? () => setHandlePicture(true)
                    : () => setHandlePicture(false)
                }
                onMouseLeave={
                  survey && survey.words_cloud_url
                    ? () => setHandlePicture(false)
                    : () => setHandlePicture(false)
                }
              >
                <p className="cursor-pointer text-black ">Image</p>
                {currentUser.profile.isCoach() ? (
                  <input
                    onChange={() => setFile(true)}
                    type="file"
                    className="hidden"
                  />
                ) : (
                  <></>
                )}
              </label>
              <DisplayPicture
                handleCollab={handlePicture}
                wordsCloud={
                  survey && survey.words_cloud_url ? survey.words_cloud_url : ''
                }
              />
              {file ? (
                <button
                  className="border text-sm px-1 py-1 bg-green-100"
                  type="submit"
                >
                  send
                </button>
              ) : (
                ''
              )}
            </form>
            <p type="submit">
              {survey && survey.words_cloud_url ? '✅ ' : '⛔️'}
            </p>
          </>
        )}
      </div>
    );
  };

  const formHandler = async (e, id) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    const url = await uploadFiles(file, id);
    await SURVEYS.updateSurvey(surveyId, { words_cloud_url: url });
    setFile(false);
  };

  return (
    <div className="h-[60px] flex  ">
      <div className="border-r-2 w-2/5  border-black  flex items-center justify-center">
        <p>{team ? team.team : 'loading'}</p>
      </div>
      <div className="border-r-2 w-1/5 border-black flex items-center justify-center relative">
        <p
          onMouseEnter={() => setHandleCollab(true)}
          onMouseLeave={() => setHandleCollab(false)}
          className="cursor-pointer"
        >
          {count} / {total}
        </p>
        <DisplayCollaborators
          team={team}
          handleCollab={handleCollab}
          collabInfos={collabInfos}
        />
      </div>
      {buildActions()}
    </div>
  );
}
