import React, { useContext, useEffect, useState } from 'react';
import { TEAMS, AuthContext, db } from '../../firebase';
import { MissionContext } from '../../hooks';
import DisplayCollaborators from '../modals/DisplayCollaborators';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, onSnapshot } from 'firebase/firestore';
// import { useTranslation } from 'react-i18next';
// import { finishOneSurveyAndClose } from '../../firebase/cloud-function';
// import { Link } from 'react-router-dom';
// import DisplayPicture from '../modals/DisplayPicture';
// import { uploadFiles } from '../../helpers/upload-file';

// const closeSurvey = async (survey) => {
//   await finishOneSurveyAndClose({ survey: survey });
// };

// const formHandler = async (e, id, setFile) => {
//   e.preventDefault();
//   const file = e.target[0].files[0];
//   const url = await uploadFiles(file, id);
//   await SURVEYS.updateSurvey(id, { words_cloud_url: url });
//   setFile(false);
// };

export default function TeamRunningRowTeamRunningRow({ surveyId }) {
  // const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [team, setTeam] = useState(null);
  const [targetTeam, setTargetTeam] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [handleCollab, setHandleCollab] = useState(false);
  const [collabInfos, setCollabInfos] = useState(null);
  const { client } = useContext(MissionContext);
  const { currentUser } = useContext(AuthContext);
  // const [handlePicture, setHandlePicture] = useState(false);
  // const [file, setFile] = useState(false);

  useEffect(async () => {
    const unsub = onSnapshot(doc(db, 'surveys', surveyId.survey_uid), (doc) => {
      setSurvey(doc.data());
    });

    return () => unsub();
  }, []);

  useEffect(async () => {
    if (survey) {
      const collabAvailable = [];
      let count = 0;
      const teamTmp = await TEAMS.getTeam(survey.team_uid);
      const targetTeamTmp = await TEAMS.getTeam(survey.target_team_uid);
      const responseUids = Object.values(survey.collab_uids);
      const collabUids = Object.keys(survey.collab_uids);

      for (let i = 0; i < responseUids.length; i++) {
        if (responseUids[i] !== null) {
          collabAvailable.push(collabUids[i]);
          count++;
        }
      }

      if (count !== 0) {
        const functions = getFunctions();
        const fetchUserData = httpsCallable(functions, 'fetchUserData');

        const response = await fetchUserData({
          requesterUID: currentUser.user.uid,
          requestedClientUID: client.id,
          requestedUserUIDS: collabAvailable,
        });

        setCollabInfos(response.data);
      }

      setTeam(teamTmp);
      setTargetTeam(targetTeamTmp);
      setTotal(Object.values(survey.collab_uids).length);
      setCount(count);
    }
  }, [survey]);

  return (
    <div className="h-[48px] flex  ">
      <div className="border-r-2 w-1/3  border-black  flex items-center justify-center">
        <p>{team ? team.team : 'loading'}</p>
      </div>
      <div className="border-r-2 w-1/3  border-black  flex items-center justify-center">
        <p>{targetTeam ? targetTeam.team : 'loading'}</p>
      </div>
      <div className="w-1/3 border-black flex items-center justify-center relative">
        <p
          onMouseEnter={() => setHandleCollab(true)}
          onMouseLeave={() => setHandleCollab(false)}
          className="cursor-pointer"
        >
          {count} / {total}
        </p>
        <DisplayCollaborators
          team={team}
          handleCollab={handleCollab}
          collabInfos={collabInfos}
        />
      </div>
      {/* {buildActions()} */}
    </div>
  );
}
