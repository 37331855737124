import React, { useEffect, useContext, useState } from 'react';
import { CLIENTS, AuthContext } from '../firebase';
import Layout from '../layout/Layout';
import DisplayClients from '../components/client/DisplayClients';

export default function Clients() {
  const { currentUser } = useContext(AuthContext);
  const [clients, setClients] = useState(null);

  useEffect(async () => {
    const response = await CLIENTS.getClientsByAdmin(currentUser.user.uid);
    setClients(response);
  }, []);

  return (
    <>
      <Layout>
        {clients ? (
          <>
            <h1 className="text-center mb-10">Clients</h1>

            <div className="border">
              <div className="flex justify-between">
                <div className="w-1/4 text-center">
                  <p>Client</p>
                </div>
                <div className="w-1/4 text-center">
                  <p>Partner</p>
                </div>
                <div className="w-1/4 text-center">
                  <p>Teams</p>
                </div>
                <div className="w-1/4 text-center">
                  <p>Collaborators</p>
                </div>
              </div>

              <DisplayClients clients={clients} />
            </div>
          </>
        ) : (
          <p className="text-center text-lg">
            No Client, please create a mission for create a new client
          </p>
        )}
      </Layout>
    </>
  );
}
