/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AuthContext } from '../firebase';
import WordCloud from '../components/cytoscape/WordCloud';
import Efforts from '../components/cytoscape/Efforts';
import { useTranslation } from 'react-i18next';
import { initCytoscape } from '../helpers/cytoscape/init-cytoscape';
import Loader from '../components/tools/Loader';

export default function Cytoscape({ survey, wordsCloud, team }) {
  const { t } = useTranslation();
  const [currentData, setCurrentData] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [managersList, setManagersList] = useState([]);
  const [loading, setLoading] = useState(true);
  let cy;
  console.log(survey);
  const truncateName = (collab) => {
    const index = collab.indexOf('@');
    return collab.substr(0, index);
  };

  useEffect(async () => {
    const responseUids = Object.values(survey.collab_uids);
    const collabUids = Object.keys(survey.collab_uids);
    const collabAvailable = [];

    for (let i = 0; i < responseUids.length; i++) {
      if (responseUids[i] !== null) {
        collabAvailable.push(collabUids[i]);
      }
    }

    const functions = getFunctions();
    const fetchUserData = httpsCallable(functions, 'fetchUserData');

    const response = await fetchUserData({
      requesterUID: currentUser.user.uid,
      requestedClientUID: team.client_uid,
      requestedUserUIDS: collabAvailable,
    });

    setManagersList(() => {
      const managersListTmp = [];

      response.data.forEach((collab) => {
        if (team.managers_uid.includes(collab.uid))
          managersListTmp.push(truncateName(collab.email));
      });

      return managersListTmp;
    });
  }, []);

  useEffect(() => {
    if (survey.map) {
      cy = initCytoscape(survey, managersList);
    }
  });

  const restoreVizu = () => {
    cy = initCytoscape(survey, managersList);
  };

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  const loader = () => {
    if (loading === true) {
      return <Loader />;
    }
  };

  const wordsCloudCoach = (question) => {
    return question.map((word) => {
      return (
        <li key={word.text}>
          {word.text}: {word.size} times
        </li>
      );
    });
  };

  return (
    <>
      <div className="h-full w-full relative">
        <p className="text-2xl  text-center mt-10 mb-10">
          {t('visualization')}{' '}
          <span className="text-2xl font-bold text-greenBaaS">{team.team}</span>
        </p>
        <div className="flex justify-center items-center  mx-40">
          {loader()}
          <div className={loading ? 'hidden' : 'block w-full'}>
            <button onClick={() => restoreVizu()} className="button mb-4">
              {t('seenByDefault')}{' '}
            </button>
            <div
              id="cy"
              className="h-[600px]  bg-slate-100  rounded-2xl shadow  mx-auto  mb-8"
            ></div>

            {wordsCloud ? <WordCloud wordsCloud={wordsCloud} /> : <></>}
            <Efforts efforts={survey.map.efforts} />
          </div>
        </div>
      </div>
    </>
  );
}
