import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MissionContext } from '../../hooks';
import AddTeamToCampaignModal from '../modals/AddTeamToCampaignModal';
import OrgaAddTeamToCampaignModal from '../modals/OrgaAddTeamToCampaignModal';
import OrgaConfigurationHeader from '../team/OrgaConfigurationHeader';
import OrgaConfigurationRow from '../team/OrgaConfigurationRow';
import TeamConfigurationHeader from '../team/TeamConfigurationHeader';
import TeamConfigurationRow from '../team/TeamConfigurationRow';

export default function CampaignRunning({ campaign }) {
  const { t } = useTranslation();
  const [openNewTeam, setOpenNewTeam] = useState(false);
  const { mission } = useContext(MissionContext);

  console.log(campaign.status);
  const displayTeamModal = () => {
    if (campaign.type === 'team') {
      return (
        <>
          {open && (
            <AddTeamToCampaignModal
              setOpen={setOpenNewTeam}
              open={openNewTeam}
              campaign={campaign}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {open && (
            <OrgaAddTeamToCampaignModal
              setOpen={setOpenNewTeam}
              open={openNewTeam}
              campaign={campaign}
            />
          )}
        </>
      );
    }
  };

  const displayTeam = () => {
    return (
      <>
        <TeamConfigurationHeader />
        {Object.keys(campaign.team_uids).map((team, index) => (
          <TeamConfigurationRow
            key={index}
            teamUid={team}
            campaign={campaign}
          />
        ))}
      </>
    );
  };

  const displayOrga = () => {
    return (
      <>
        <OrgaConfigurationHeader />
        {Object.keys(campaign.relations).map((team, index) => (
          <OrgaConfigurationRow
            key={index}
            relations={campaign.relations[team]}
            teamUid={team}
            campaign={campaign}
          />
        ))}
      </>
    );
  };

  const isDisplayTeam = () => {
    if (mission.type === 'team') {
      return displayTeam();
    } else {
      return displayOrga();
    }
  };

  return (
    <>
      {displayTeamModal()}

      <div className="mb-8 border-2 border-black min-h-[100px] rounded">
        {isDisplayTeam()}
      </div>
      <div className="flex justify-center my-4">
        <button onClick={() => setOpenNewTeam(true)} className="button">
          {t('addTeam')}
        </button>
      </div>
    </>
  );
}
