import { useState, useEffect, useContext } from 'react';
import { TEAMS, AuthContext, CLIENTS } from '../../firebase';
import {
  createUser,
  fetchUserData,
  updateUserPassword,
} from '../../firebase/cloud-function';

export default function DisplayMember({ selectedTeam, client }) {
  const [members, setMembers] = useState([]);
  const [newMember, setNewMember] = useState('');
  const { currentUser } = useContext(AuthContext);
  const [manager, setManager] = useState([]);

  useEffect(async () => {
    if (selectedTeam) {
      const response = await fetchUserData({
        requesterUID: currentUser.user.uid,
        requestedClientUID: selectedTeam.client_uid,
        requestedUserUIDS: selectedTeam.collab_uids,
      });

      setMembers(response.data);
    }
    if (selectedTeam) {
      setManager(selectedTeam.managers_uid);
    }
  }, [selectedTeam]);

  const handlerClient = async (email) => {
    const clientTmp = await createUser({ email });
    return clientTmp.data;
  };

  const handleCreateNewMember = async (email) => {
    const response = await handlerClient(email);
    await CLIENTS.addCollabToClient(client.uid, response);
    await TEAMS.addUserInTeam(selectedTeam.uid, response);
    setMembers([
      ...members.filter((m) => m.uid !== response),
      { email, uid: response },
    ]);
    setNewMember('');
  };

  const handleDeleteMember = async (collabId) => {
    const newTeam = members.filter((m) => m.uid !== collabId);
    setMembers(newTeam);
    await TEAMS.removeUserFromTeam(selectedTeam.uid, collabId);
  };

  const handleAddManager = async (collabId) => {
    await TEAMS.addUserAsManager(selectedTeam.uid, collabId);
    setManager([...manager.filter((m) => m !== collabId), collabId]);
  };
  const handleRemoveManager = async (collabId) => {
    await TEAMS.RemoveUserAsManager(selectedTeam.uid, collabId);

    setManager(manager.filter((m) => m !== collabId));
  };

  const displayHandleManager = (uid) => {
    if (!manager.includes(uid)) {
      return (
        <button
          className="bg-blue-300 hover:bg-blue-100 w-full h-8 "
          onClick={() => handleAddManager(uid)}
        >
          Add As Manager
        </button>
      );
    } else {
      return (
        <button
          className="bg-red-300 hover:bg-red-100 w-full h-8"
          onClick={() => handleRemoveManager(uid)}
        >
          Remove as Manager
        </button>
      );
    }
  };

  const handleRestorePassword = async (user) => {
    const response = await updateUserPassword({ user: user.uid });
    console.log(response);
    if (response.data)
      return alert(`✅ password of ${user.email} is reset to 123abcDEF ✅`);
    alert(`⛔️ password of ${user.email} not changed ⛔️`);
  };

  const displayResetPassword = (user) => {
    return (
      <button
        className="bg-gray-400 w-full h-8 hover:bg-gray-200 mt-2"
        onClick={() => handleRestorePassword(user)}
      >
        Restore Password
      </button>
    );
  };

  const displayTeam = () => {
    if (members) {
      return members.map((t) => {
        return (
          <div key={t.uid} className="mb-4 border p-2">
            <div className="flex justify-between">
              <p
                className={manager.includes(t.uid) ? 'block text-lg' : 'hidden'}
              >
                👩‍🍳
              </p>
              <p className="text-md">{t.email}</p>
              <button onClick={() => handleDeleteMember(t.uid)}>X</button>
            </div>

            {displayHandleManager(t.uid)}
            {displayResetPassword(t)}
          </div>
        );
      });
    } else {
      return 'no team yet';
    }
  };

  return (
    <>
      {displayTeam()}
      {selectedTeam && (
        <div className="border bg-green-100 p-4">
          <p>Add Collaborator to this team</p>
          <input
            className="border-2 rounded p-2 mb-4 h-10"
            placeholder="Collab Email"
            type="text"
            value={newMember}
            onChange={(e) => setNewMember(e.target.value)}
          />
          <button
            onClick={() => handleCreateNewMember(newMember)}
            className="button w-full"
          >
            Add
          </button>
        </div>
      )}
    </>
  );
}
