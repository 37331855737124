/* eslint-disable no-undef */
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function WordCloud({ wordsCloud }) {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-2xl  text-center mt-10 mb-10">{t('words')} </p>
      <div
        className=" border rounded-2xl shadow bg-grey flex justify-center h-[500px] w-full bg-contain bg-no-repeat  bg-center"
        style={{ backgroundImage: `url(${wordsCloud})` }}
      >
        {/* <img className="h-auto" src={wordsCloud} /> */}
      </div>
    </>
  );
}
