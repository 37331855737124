/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MISSIONS } from '../firebase';
import Layout from '../layout/Layout';
import { useTranslation } from 'react-i18next';

export default function CreateCompaign() {
  const { t } = useTranslation();
  const [campaignTitle, setCampaignTitle] = useState('');
  const [mission, setMission] = useState('');
  const params = useParams();
  const navigate = useNavigate();

  useEffect(async () => {
    const mission = await MISSIONS.getMission(params.mission_id);
    setMission(mission);
  }, []);

  const createCampaign = async () => {
    if (mission.type === 'team' && !mission.relation_with_orga) {
      const campaignId = await MISSIONS.createCampaign(campaignTitle);
      await MISSIONS.addCampaignToMission(params.mission_id, campaignId);
    } else if (mission.type === 'organisation' && !mission.relation_with_team) {
      const campaignId = await MISSIONS.createCampaignOrga(campaignTitle);
      await MISSIONS.addCampaignToMission(params.mission_id, campaignId);
    } else if (mission.type === 'team' && mission.relation_with_orga) {
      const ids = await MISSIONS.createCampaignOrgaAndTeam(campaignTitle);
      await MISSIONS.addCampaignToMission(
        mission.relation_with_orga,
        ids.orgaId
      );
      await MISSIONS.addCampaignToMission(params.mission_id, ids.teamId);
    } else if (mission.type === 'organisation' && mission.relation_with_team) {
      const ids = await MISSIONS.createCampaignOrgaAndTeam(campaignTitle);
      await MISSIONS.addCampaignToMission(
        mission.relation_with_team,
        ids.teamId
      );
      await MISSIONS.addCampaignToMission(params.mission_id, ids.orgaId);
    }
    navigate(`/missions/${params.mission_id}`);
  };

  return (
    <>
      <Layout>
        <h1 className="text-[38px] font-bold text-greenBaaS mt-10">
          {mission && mission.title}
        </h1>
        <h2 className="text-[24px] font-bold text-greenBaaS mt-5 mb-16">
          {t('newCampaign')}
        </h2>

        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center mb-8">
            <input
              className="h-12 w-64 border rounded-xl pl-4 shadow"
              type="text"
              name="cheese"
              id="cheese"
              placeholder={t('campaignName')}
              value={campaignTitle}
              onChange={(e) => setCampaignTitle(e.target.value)}
            />
          </div>

          <button onClick={createCampaign} className="button">
            {t('create')}
          </button>
        </div>
      </Layout>
    </>
  );
}
