import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, CLIENTS } from '../firebase';

import Layout from '../layout/Layout';
import ArrayTeamConfigurationCampaign from '../components/coach-dashboard/ArrayTeamConfigurationCampaign';
import AddTeamToCampaignModal from '../components/modals/AddTeamToCampaignModal';

import { SURVEYS } from '../firebase';

export default function TeamConfigurationMission() {
  const [openNewTeam, setOpenNewTeam] = useState(false);
  const [client, setClient] = useState();
  const [surveys, setSurveys] = useState();

  const params = useParams();

  useEffect(async () => {
    const unsub = onSnapshot(
      doc(db, 'missions', params.mission_id),
      async (doc) => {
        let missionTmp = doc.data();
        const clientTmp = await CLIENTS.getClient(missionTmp.client_uid);
        const campaign = doc
          .data()
          .campaigns.filter((c) => c.id === params.campaign_id);
        setClient(clientTmp);
        const surveysTmp = await SURVEYS.getSurveysBulk(
          campaign[0].surveys_uid
        );
        setSurveys(surveysTmp);
      }
    );
    return () => unsub();
  }, []);

  const surveysList = () => {
    if (surveys) {
      return surveys.map((survey, index) => {
        return (
          <>
            <div className="h-[48px] flex" key={index}>
              <div className=" w-2/5   flex items-center justify-center">
                <p>{survey.team_name}</p>
              </div>

              <div className=" w-1/5 flex items-center justify-center">
                <p>{survey.collaborators.length}</p>
              </div>
              <div className="w-2/5 flex items-center justify-center">
                <button className="px-3 py-2 w-32 bg-greenButtonBaaS hover:bg-greenBaaS text-white rounded-2xl mr-4">
                  Modifier
                </button>
                <button className="px-3 py-2 w-32 bg-red-600 hover:bg-red-300 text-white rounded-2xl">
                  retirer
                </button>
              </div>
            </div>
          </>
        );
      });
    } else {
      return <p>En chargement</p>;
    }
  };

  return (
    <>
      <AddTeamToCampaignModal
        open={openNewTeam}
        setOpen={setOpenNewTeam}
        client={client}
        missionId={params.mission_id}
      />
      <Layout>
        <h1 className="text-[38px] font-bold text-greenBaaS mt-10">
          Campagnes
        </h1>
        <ArrayTeamConfigurationCampaign>
          {surveysList()}
        </ArrayTeamConfigurationCampaign>

        <div className="float-right">
          <button
            onClick={() => setOpenNewTeam(true)}
            className="h-16 w-16 rounded-full bg-greenButtonBaaS hover:bg-greenBaaS text-white font-bold text-2xl"
          >
            +
          </button>
        </div>
      </Layout>
    </>
  );
}
