import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';

const uploadFiles = async (file, id) => {
  if (!file) return;
  const storageRef = ref(storage, `/pictures/${id}}`);

  const uploadTask = await uploadBytes(storageRef, file);
  const urlTmp = await getDownloadURL(uploadTask.ref);

  // Upload the file and metadata

  return urlTmp;
};

export { uploadFiles };
