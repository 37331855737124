import DisplayClient from './DisplayClient';

export default function DisplayClients({ clients }) {
  const display = clients.map((t) => {
    return (
      <div key={t.uid}>
        <DisplayClient client={t} />
      </div>
    );
  });

  return <>{display}</>;
}
