import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../firebase';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Layout from '../layout/Layout';

import relationEn from '../assets/img/relation-en.jpeg';
import relationFr from '../assets/img/relation-fr.jpeg';
import pipe from '../assets/img/broken-pipe.jpeg';

export default function Welcome() {
  const { t } = useTranslation();

  const { currentUser } = useContext(AuthContext);
  const [role, setRole] = useState('');
  const [lang, setLang] = useState('en');

  useEffect(() => {
    if (currentUser.profile.isCoach()) {
      setRole('Coach');
    } else if (currentUser.profile.isCollab()) {
      setRole('Collab');
    } else if (currentUser.profile.isAdmin()) {
      setRole('Admin');
    } else if (currentUser.profile.isManagingPartner()) {
      setRole('ManagingPartner');
    } else {
      setRole('Role inconnu');
    }

    i18next.on('languageChanged', function (lng) {
      setLang(lng);
    });
  }, []);

  const imgDisplay = () => {
    if (lang === 'en') {
      return (
        <div className="flex">
          <img className="w-1/2" src={relationEn}></img>
          <img className="w-1/2" src={pipe}></img>
        </div>
      );
    } else if (lang === 'fr') {
      return (
        <div className="flex">
          <img className="w-1/2" src={relationFr}></img>
          <img className="w-1/2" src={pipe}></img>
        </div>
      );
    }
  };

  return (
    <>
      <Layout>
        <p className="text-[20px] mt-10 mb-5">{t(`welcomeTo${role}1`)}</p>
        {imgDisplay()}
        <p className="text-[20px] mt-10 mb-5">{t(`welcomeTo${role}2`)}</p>
        <p className="text-[20px] mt-10 mb-2">{t('happyPro')}</p>
        <p className="text-[20px]">{t('verticilleTeam')}</p>
      </Layout>
    </>
  );
}
