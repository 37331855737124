import React from 'react';
import { useContext, useState, useEffect } from 'react';
import { AuthContext, CLIENTS } from '../../firebase';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../helpers/capitalize';

import { Link } from 'react-router-dom';

export default function MissionRow({ mission }) {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const [clientName, setClientName] = useState(null);

  useEffect(async () => {
    if (currentUser.profile.isCoach()) {
      let client = await CLIENTS.getClient(mission.client_uid);
      if (client) {
        setClientName(client.name);
      }
    }
  }, []);

  const addClientName = () => {
    if (currentUser.profile.isCoach()) {
      return (
        <div className="h-full w-1/4 border-r-2 border-black flex items-center justify-center">
          <p>{clientName ?? t('unknowClient')}</p>
        </div>
      );
    }
  };

  return (
    <div className="h-[48px] flex  border-b border-black">
      {addClientName()}
      <div className="border-r-2 h-full border-black w-2/4 flex items-center justify-center">
        <p>{capitalizeFirstLetter(mission.title)}</p>
      </div>
      <div className="border-r-2 flex items-center justify-center  border-black w-2/4  ">
        <p>{capitalizeFirstLetter(mission.type)}</p>
        {mission.relation_name && (
          <>
            <p className="mx-4 text-lg">⚯</p> <p>{mission.relation_name}</p>
          </>
        )}
      </div>
      <div className="border-r-2 border-black w-1/4 flex items-center justify-center">
        {mission.status}
      </div>
      <div className="flex w-1/4 items-center justify-center">
        <Link to={`${mission.uid}`} className="button">
          {t('details')}
        </Link>
      </div>
    </div>
  );
}
