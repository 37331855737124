import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../firebase';
import { MissionContext } from '../../hooks';
import { capitalizeFirstLetter } from '../../helpers/capitalize';

import { useTranslation } from 'react-i18next';

export default function MissionTopBar() {
  const { client, mission } = useContext(MissionContext);
  const { currentUser } = useContext(AuthContext);

  const { t } = useTranslation();

  return (
    <div className="w-full border border-black shadow flex justify-between items-center  p-4 rounded-2xl">
      <div>
        {
          // Show client name only for coaches
          currentUser.profile.isCoach() ? (
            <p className="text-lg text-greenBaaS font-bold">
              {client ? client.name : t('unknowClient')}
            </p>
          ) : (
            <></>
          )
        }

        <p className="text-lg text-greenBaaS font-bold">
          {mission ? capitalizeFirstLetter(mission.title) : t('unknowMission')}
        </p>
        <p className="text-lg text-greenBaaS font-bold">
          {mission ? capitalizeFirstLetter(mission.type) : t('unknowMission')}
        </p>
      </div>

      {
        // Show mission action buttons only for coaches
        currentUser.profile.isCoach() ? (
          <div>
            <Link to="create-campaign" className="button mr-4">
              {t('newCampaign')}
            </Link>
            <Link to="/" className="button">
              {t('finishMission')}
            </Link>
          </div>
        ) : (
          <></>
        )
      }
    </div>
  );
}
