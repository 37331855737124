import { createContext, useState } from 'react';

const ViewOrgaContext = createContext({
  selectedSurvey: [],
  setSelectedSurvey: () => {},
});

function ViewOrgaProvider({ children, ...props }) {
  const [selectedSurvey, setSelectedSurvey] = useState([]);
  const values = { selectedSurvey, setSelectedSurvey };

  return (
    <ViewOrgaContext.Provider value={values} {...props}>
      {children}
    </ViewOrgaContext.Provider>
  );
}

export { ViewOrgaContext, ViewOrgaProvider };
