import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CampaignStatus({ campaign }) {
  const { t } = useTranslation();

  if (campaign && campaign.status === 0) {
    return (
      <p className="px-3 py-2 rounded-xl text-white	 bg-purpleBaaS mr-8">
        {t('draft')}
      </p>
    );
  } else if (campaign && campaign.status === 1) {
    return (
      <p className="px-3 py-2 rounded-xl text-white	 bg-purpleBaaS mr-8">
        {t('inProgress')}
      </p>
    );
  } else {
    return (
      <p className="px-3 py-2 rounded-xl text-white	 bg-greenBaaS mr-8">
        {t('ended')}
      </p>
    );
  }
}
