export class UserProfile {
  setAdminClientUId(clientUId) {
    this.admin_client_uid = clientUId;
  }

  setCollabClientUId(clientUId) {
    this.collab_client_uid = clientUId;
  }

  setManagingPartner(clientUId) {
    this.managing_partner_client_uid = clientUId;
  }

  setCoach(coach) {
    this.coach = coach;
  }

  isCoach() {
    return this.coach;
  }

  isAdmin() {
    return this.admin_client_uid != undefined;
  }

  isManagingPartner() {
    return this.managing_partner_client_uid != undefined;
  }

  isCollab() {
    return this.collab_client_uid != undefined;
  }
}
