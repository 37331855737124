import React, { useState, useEffect, useContext } from 'react';
import { MissionContext } from '../../hooks';
import { TEAMS, CAMPAIGNS, CLIENTS, AuthContext } from '../../firebase';
import { fetchUserData } from '../../firebase/cloud-function';
import Modal from '../../layout/Modal';
// import OrgaHandleSelectTeam from '../modal-components/OrgaHandleSelectTeam';
import OrgaList from '../modal-components/OrgaList';
import TeamList from '../modal-components/TeamList';
import { useTranslation } from 'react-i18next';
import { SearchDropdown } from '../tools/SearchDropdown';

export default function OrgaAddTeamToCampaignModal({
  open,
  setOpen,
  campaign,
}) {
  const { t } = useTranslation();
  // const [newTeam, setNewTeam] = useState(false);
  const [teams, setTeams] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [selectionEvaluators, setSelectionEvaluators] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const [targetSelectedTeam, setTargetSelectedTeam] = useState(null);
  const [collabTeam, setCollabTeam] = useState([]);
  const { mission } = useContext(MissionContext);
  const { currentUser } = useContext(AuthContext);

  useEffect(async () => {
    const teamsTmp = await TEAMS.getClientTeams(mission.client_uid);
    setTeams(teamsTmp);
  }, [selectedTeam]);

  useEffect(async () => {
    const collabUidsTmp = await CLIENTS.getCollaboratos(mission.client_uid);
    const response = await fetchUserData({
      requesterUID: currentUser.user.uid,
      requestedClientUID: mission.client_uid,
      requestedUserUIDS: collabUidsTmp,
    });
    setCollaborators(response);
  }, []);

  useEffect(() => {
    setSelectionEvaluators([]);
    setSelectedTeam(null);
    setCollabTeam([]);
  }, [open]);

  const handleSubmit = async () => {
    if (
      (targetSelectedTeam && selectedTeam) ||
      selectionEvaluators.length !== 0
    ) {
      const selectionEvaluatorsTmp = selectionEvaluators;
      collabTeam.forEach((t) => {
        t.collab_uids.forEach((m) => {
          if (!selectionEvaluatorsTmp.includes(m))
            selectionEvaluatorsTmp.push(m);
        });
      });

      console.log(selectionEvaluatorsTmp);
      await CAMPAIGNS.orgaAddTeamToCampaign(
        campaign.uid,
        selectedTeam.uid,
        targetSelectedTeam.uid,
        selectionEvaluatorsTmp
      );
      setSelectedTeam(null);
      setCollabTeam([]);
      setTargetSelectedTeam(null);
      setOpen(false);
    }
  };

  // AFFICHAGE TEAM

  const mainTeam = () => {
    if (teams) {
      return teams.map((team, index) => {
        return (
          <div key={index} className="flex items-center mb-4">
            <TeamList
              setSelectedTeam={setSelectedTeam}
              selectedTeam={selectedTeam}
              team={team}
            />
          </div>
        );
      });
    } else {
      return (
        <>
          <p>No existing team</p>
        </>
      );
    }
  };
  const displaySelectedTeam = () => {
    if (teams) {
      return teams.map((team, index) => {
        return (
          <div key={index} className="flex items-center mb-4">
            <OrgaList
              setSelectedTeam={setCollabTeam}
              selectedTeam={collabTeam}
              team={team}
            />
          </div>
        );
      });
    } else {
      return (
        <>
          <p>No existing team</p>
        </>
      );
    }
  };

  const targetTeam = () => {
    if (teams) {
      return teams.map((team, index) => {
        return (
          <div key={index} className="flex items-center mb-4">
            <TeamList
              setSelectedTeam={setTargetSelectedTeam}
              selectedTeam={targetSelectedTeam}
              team={team}
            />
          </div>
        );
      });
    } else {
      return (
        <>
          <p>No existing team</p>
        </>
      );
    }
  };

  // AFFICHAGE TEAM SELECTIONNEE

  const handleNewClient = () => {
    return (
      <>
        <div className="flex">
          <div className="w-1/2 flex flex-col border rounded p-2 mr-1">
            <p>Main team</p>
            {mainTeam()}
          </div>
          <div className="w-1/2 flex flex-col border rounded p-2 ml-1">
            <p>Target team</p>
            {targetTeam()}
          </div>
        </div>
        <div className="flex flex-col border rounded p-2 mr-1">
          <p>Selecte Team</p>
          {displaySelectedTeam()}
        </div>
        <SearchDropdown
          collaborators={collaborators.data}
          selectionEvaluators={selectionEvaluators}
          setSelectionEvaluators={setSelectionEvaluators}
        />
      </>
    );
  };
  // const handleNewClient = () => {
  //   if (!newTeam) {
  //     return (
  //       <>
  //         <div className="flex">
  //           <div className="w-1/2 flex flex-col border rounded p-2 mr-1">
  //             <p>Main team</p>
  //             {mainTeam()}
  //           </div>
  //           <div className="w-1/2 flex flex-col border rounded p-2 ml-1">
  //             <p>Target team</p>
  //             {targetTeam()}
  //           </div>
  //         </div>
  //         <div className="flex flex-col border rounded p-2 mr-1">
  //           <p>Selecte Team</p>
  //           {displaySelectedTeam()}
  //         </div>
  //         <SearchDropdown
  //           collaborators={collaborators.data}
  //           selectionEvaluators={selectionEvaluators}
  //           setSelectionEvaluators={setSelectionEvaluators}
  //         />
  //       </>
  //     );
  //   } else {
  //     return (
  //       <div className="flex items-center">
  //         <OrgaHandleSelectTeam
  //           setSelectedTeam={setSelectedTeam}
  //           mission={mission}
  //         />
  //       </div>
  //     );
  //   }
  // };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex justify-between items-end mb-4">
        <p className="text-greenBaaS font-bold text-lg">{t('selectTeam')}</p>
      </div>
      <div className="border rounded-2xl shadow p-4 mb-4">
        {handleNewClient()}
      </div>
      <button
        onClick={() => handleSubmit(selectedTeam)}
        className="w-32 py-2 bg-greenButtonBaaS rounded-2xl hover:bg-greenBaaS text-white"
      >
        {t('add')}
      </button>
    </Modal>
  );
}
