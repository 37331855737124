import { Parser } from 'json2csv';
import fileDownload from 'js-file-download';

export const downloadCsv = (item, title, orga = false) => {
  if (orga) {
    const questions = item.map.questionByTeam;
    console.log(item.map.questionByTeam);
    let fields = [];
    for (let i = 0; i < questions.length; i++) {
      console.log(Object.keys(questions[i]));
      Object.keys(questions[i]).forEach((q) => {
        fields.push(q);
      });
    }
    const bookByTeam = new Parser({
      fields,
    }).parse(questions);
    fileDownload(bookByTeam, `orga-${title}-word-cloud.csv`);
  }

  const book = new Parser({
    fields: ['question_1', 'question_2', 'question_3'],
  }).parse(item.map.jsonToCsv);
  fileDownload(book, `${title}-word-cloud.csv`);
};
