import React, { useContext } from 'react';

import { Widget } from '@typeform/embed-react';
import { updateSurvey } from '../firebase/cloud-function';
import { useNavigate } from 'react-router-dom';
import { SURVEYS, AuthContext } from '../firebase';

export default function TypeFormSurvey({ survey }) {
  const location = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const redirect = () => {
    setTimeout(() => {
      location(`/surveys`);
    }, 2000);
  };

  const handleSubmit = async (event) => {
    await SURVEYS.updateSurveyOrga(
      survey.uid,
      currentUser.user.uid,
      event.responseId
    );
    // cloud function security
    const submit = await updateSurvey({
      survey,
      responseId: event.responseId,
    });

    if (submit.data) {
      redirect();
    }
  };

  return (
    <>
      <Widget
        id={survey.typeform_id}
        size={100}
        style={{
          fontSize: 16,
          color: 'white',
          height: '100%',
          padding: 0,
        }}
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        click to open form in popup
      </Widget>
    </>
  );
}
