import React, { useContext } from 'react';
import LogLayout from '../layout/LogLayout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../firebase';

export default function ForgottenPassword() {
  const [email, setEmail] = useState(null);
  const [error, setError] = useState('');
  const { resetPassword } = useContext(AuthContext);

  //const [firebaseError, setFirebaseError] = useState('');
  const navigate = useNavigate();

  const reinitialise = async (e) => {
    e.preventDefault;
    console.log('Reinitalisating email', email);

    const success = await resetPassword(email);
    if (success) {
      console.log('Reset password successfully sent');
      navigate('/login');
    } else {
      setError('An error occured during password reset');
    }
  };

  return (
    <>
      <LogLayout>
        <div></div>
        <h1 className="text-[36px] text-greenBaaS text-bold mt-4 mb-4">
          Update your password
        </h1>
        <p className="text-xl text-center h-16 text-black mb-5 mx-10">
          choose a new password to access the application
        </p>
        {/* <form
          onSubmit={e => reinitialise(e)}
          className="w-2/3 flex flex-col items-center"
        > */}
        <input
          name="email"
          placeholder="john.doe@example.com"
          autoComplete="off"
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          className="w-2/3 bg-white py-4 px-2 mb-8  border shadow-sm  rounded-xl"
        />

        <p className="text-xs h-8 text-red-600 mt-5">{error}</p>
        <button
          onClick={reinitialise}
          className="bg-greenButtonBaaS text-white w-1/2 py-4 hover:bg-greenBaaS rounded-[100px] mb-2"
        >
          Reset
        </button>
        {/* </form> */}
      </LogLayout>
    </>
  );
}
