/* eslint-disable no-unused-vars */
import { buildTooltip, hideTooltip, hideAllTippies } from '../tooltips';
// import popper from 'cytoscape-popper';

export const initCytoscape = (survey, managersList) => {
  // eslint-disable-next-line no-undef
  const cy = cytoscape({
    container: document.getElementById('cy'),

    elements: survey.map.map_data,

    style: [
      {
        selector: 'node[name]',
        style: {
          content: 'data(name)',
          width: 5,
          height: 5,
          'text-valign': 'center',
          'text-halign': 'center',
          fontSize: '3px',
        },
      },

      {
        selector: 'edge',
        style: {
          width: 0.5,
          'line-color': '#ccc',
          'target-arrow-color': '#F1F5F9',
          // 'curve-style': 'curve',
        },
      },
      {
        selector: '[?highlighted]',
        style: {
          opacity: '1',
        },
      },
      {
        selector: '[!highlighted]',
        style: {
          opacity: '0.10',
        },
      },
    ],

    layout: {
      name: 'concentric',
      padding: 50,
      nodeDimensionsIncludeLabels: true,
    },
  });
  cy.on('tap', function (e) {
    if (e.target === cy) {
      hideAllTippies(cy);
    }
  });

  cy.nodes().forEach(function (node) {
    managersList.includes(node._private.data.name)
      ? node.style('font-weight', 'bold')
      : node.style('font-weight', 'normal');

    node.style('border-color', 'grey');
    node.style('border-width', '0.1');
  });

  cy.edges().forEach(function (edge) {
    buildTooltip(edge, survey.map.lang);
  });

  cy.edges().on('mouseover', function (event) {
    let edge = event.target;
    if (edge.data('highlighted')) {
      var width = edge.numericStyle('width');
      edge.style('width', width * 2);
      edge.data('tippy').show();
    }

    event.cy.nodes().not(edge).forEach(hideTooltip);
  });

  cy.edges().on('mouseout', function (e) {
    let edge = e.target;
    if (edge.data('highlighted')) {
      var width = edge.numericStyle('width');
      edge.style('width', width / 2);
      edge.data('tippy').hide();
    }
  });

  cy.edges().forEach(function (edge) {
    let dimensions = edge.data('dimensions');
    const result = dimensions.reduce(function (prev, curr) {
      return prev.value < curr.value ? prev : curr;
    });

    switch (result.key) {
      case 'meaning':
        edge.style('line-color', '#4e8cff');
        break;
      case 'energy':
        edge.style('line-color', '#fddc70');
        break;
      case 'time':
        edge.style('line-color', '#9177ff');
        break;
      case 'space':
        edge.style('line-color', '#14c1b2');
        break;
      case 'emotion':
        edge.style('line-color', '#F16C6C');
        break;
    }
  });

  return cy;
};
