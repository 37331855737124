import React, { useContext } from 'react';
import { AuthContext } from '../firebase';
import { NavLink } from 'react-router-dom';

import logo from '../assets/img/pro_logo_brand 1.png';
import profile_icon from '../assets/img/profile_icon.png';
import { useTranslation } from 'react-i18next';
import { i18next } from '../helpers/i18n/i18next';

const handleVersion = (version) => {
  i18next(version);
};

i18next('en');

export default function Header() {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);

  let activeStyle = {
    textDecoration: 'underline',
  };

  const changeLang = (version) => {
    handleVersion(version);
  };

  const connexion = () => {
    if (currentUser !== null) {
      return (
        <div>
          <p className="text-white font-bold text-lg">
            {currentUser.user.email}
          </p>
          <div className="flex justify-end items-center">
            <p
              id="en-button"
              onClick={() => changeLang('en')}
              className="text-lg mr-2 cursor-pointer"
            >
              🇬🇧
            </p>
            <p
              id="fr-button"
              onClick={() => changeLang('fr')}
              className="text-lg mr-3 cursor-pointer"
            >
              🇫🇷
            </p>
            <p
              onClick={logout}
              className="cursor-pointer text-white text-right mb-2"
            >
              {t('logout')}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <NavLink className="text-white text-right" to="/login">
          {t('login')}
        </NavLink>
      );
    }
  };

  const clients = () => {
    if (currentUser !== null && currentUser.profile.isCoach()) {
      return (
        <NavLink className="text-white mx-10" to="/clients">
          Clients
        </NavLink>
      );
    }
  };

  const missions = () => {
    if (currentUser !== null) {
      if (
        currentUser.profile.isAdmin() ||
        currentUser.profile.isCoach() ||
        currentUser.profile.isManagingPartner()
      ) {
        return (
          <NavLink className="text-white mx-10" to="/missions">
            Missions
          </NavLink>
        );
      }
    }
  };

  const surveys = () => {
    if (currentUser !== null && !currentUser.profile.isCoach()) {
      return (
        <NavLink className="text-white" to="/surveys">
          {t('surveys')}
        </NavLink>
      );
    }
  };

  return (
    <nav className="h-[74px] bg-greenBaaS flex items-center px-6">
      <div className="flex gap-2 mr-10">
        <NavLink
          to={`/welcome`}
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          <img src={logo} alt="logo" />
        </NavLink>
      </div>

      {clients()}
      {missions()}
      {surveys()}
      <div className="flex-grow"></div>
      <NavLink to={`/profile`}>
        <img
          src={profile_icon}
          alt="profile"
          className="h-[48px] mr-5 cursor-pointer"
        />
      </NavLink>

      {connexion()}
    </nav>
  );
}
