import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { i18next } from '../../helpers/i18n/i18next';

const handleVersion = (version) => {
  i18next(version);
};

export default function Menu({ campaign }) {
  const { t } = useTranslation();
  useEffect(() => {
    if (campaign) {
      handleVersion(campaign.map.lang);
    }
  }, [campaign]);

  const sumValue = () => {
    if (campaign) {
      return campaign.map.averages.map((sum, index) => {
        return (
          <li key={index} className="flex justify-between text-sm">
            - {t(sum.key)}: <span>{sum.value}</span>
          </li>
        );
      });
    }
  };

  const differenceValue = () => {
    if (campaign) {
      return campaign.map.differences.map((dif, index) => {
        return (
          <li key={index} className="flex justify-between text-sm">
            - {t(dif.key)}: <span>{dif.value}</span>
          </li>
        );
      });
    }
  };

  const poleGap = () => {
    if (campaign) {
      return campaign.map.differences_max_min.map((dif, index) => {
        return (
          <li key={index} className="flex justify-between text-sm">
            - {t(dif.key)}:{' '}
            <span>
              {dif.min} | {dif.max}
            </span>
          </li>
        );
      });
    }
  };

  return (
    <>
      <div className="w-[300px] h-full border-r-2 shadow  bg-slate-100 px-8 py-8">
        <div className="border-b-2 mb-4">
          <p className="text-greenBaaS mb-2">
            {t('organization')}:
            <span className="font-bold">
              {campaign ? campaign.title : 'loading'}
            </span>
          </p>
          <p className="text-greenBaaS mb-4">
            Date:
            <span className="font-bold">
              {campaign
                ? moment(new Date(campaign.created_at)).format('MMM Do YY')
                : 'loading'}
            </span>
          </p>
        </div>

        <div className="border-b-2 mb-4">
          <p className="text-greenBaaS mb-2 font-bold text-center">
            {t('average')}:
          </p>
          <ul className="mb-4">{sumValue()}</ul>
        </div>

        <div className="border-b-2 mb-4">
          <p className="text-greenBaaS mb-2 font-bold text-center">
            {t('standardDeviations')}:
          </p>
          <ul className="mb-5">{differenceValue()}</ul>
        </div>

        <div className="border-b-2 mb-4">
          <p className="text-greenBaaS mb-2 font-bold text-center">
            {t('minMax')}:
          </p>
          <ul className="mb-5">{poleGap()}</ul>
        </div>

        <div className="text-center">
          <p className="text-greenBaaS mb-4 font-bold">{t('categories')}:</p>
          <div className="flex flex-col justify-center items-center">
            <div className="bg-[#4e8cff] w-full py-1 rounded-2xl mb-2 text-white text-sm ">
              {t('meaning')}
            </div>

            <div className="bg-[#fddc70] w-full py-1 rounded-2xl mb-2 text-white text-sm ">
              {t('energy')}
            </div>

            <div className="bg-[#9177ff] w-full py-1 rounded-2xl mb-2 text-white text-sm ">
              {t('time')}
            </div>

            <div className="bg-[#14c1b2] w-full py-1 rounded-2xl mb-2 text-white text-sm ">
              {t('space')}
            </div>

            <div className="bg-[#f16c6c] w-full py-1 rounded-2xl text-white text-sm ">
              {t('emotion')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
