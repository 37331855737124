import React from 'react';
import { useContext } from 'react';

import CampaignCard from '../campaign/CampaignCard';

import { useTranslation } from 'react-i18next';
import { MissionContext } from '../../hooks';

export default function CampaignList() {
  const { t } = useTranslation();

  const { mission } = useContext(MissionContext);

  const campaignsList = () => {
    if (mission && mission.campaign_uids.length !== 0) {
      return mission.campaign_uids.map((campaignUid, index) => {
        return (
          <div
            key={index}
            className="border shadow border-black min-h-[100px] rounded-xl mt-10 p-4"
          >
            <CampaignCard campaignUid={campaignUid} active={true} />
          </div>
        );
      });
    } else {
      return <p className="text-center mt-10">{t('noCampaignYet')}</p>;
    }
  };

  return <>{campaignsList()}</>;
}
