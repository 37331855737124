import { db } from './index';
import { getDoc, documentId, updateDoc, doc } from 'firebase/firestore';
import { query, where, collection, getDocs } from 'firebase/firestore';

const CAMPAIGNS = {
  getCampaign: async (id) => {
    const docRef = await getDoc(doc(db, 'campaigns', id));

    let campaignTmp = docRef.data();
    campaignTmp.uid = docRef.id;
    return campaignTmp;
  },

  getCampaignBulk: async (campaignUIDS, minStatus = 0) => {
    const q = query(
      collection(db, 'campaigns'),
      where(documentId(), 'in', campaignUIDS)
    );
    const querySnapshot = await getDocs(q);
    const campaigns = [];
    querySnapshot.forEach((doc) => {
      let new_campaign = doc.data();
      new_campaign.uid = doc.id;

      // Filter campaign with min status
      if (new_campaign.status >= minStatus) {
        campaigns.push(new_campaign);
      }
    });
    return campaigns;
  },

  addTeamToCampaign: async (id, teamUid) => {
    try {
      const docRef = await getDoc(doc(db, 'campaigns', id));

      let addTeam = docRef.data().team_uids;
      addTeam[teamUid] = null;
      await updateDoc(doc(db, 'campaigns', id), {
        team_uids: addTeam,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  orgaAddTeamToCampaign: async (
    id,
    teamUid,
    targetTeamUid,
    selectionEvaluators
  ) => {
    try {
      const docRef = await getDoc(doc(db, 'campaigns', id));
      let addTeam = docRef.data().relations;
      addTeam[teamUid + '-' + targetTeamUid] = {
        survey_uid: null,
        team_uid: teamUid,
        target_team_uid: targetTeamUid,
        evaluator_uids: selectionEvaluators,
      };
      await updateDoc(doc(db, 'campaigns', id), {
        relations: addTeam,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  orgaAddicture: async (campaign, pictureUrl) => {
    try {
      const docRef = await getDoc(doc(db, 'campaigns', campaign.uid));
      let campaignTmp = docRef.data();

      campaignTmp.map.word_cloud_url = pictureUrl;

      await updateDoc(doc(db, 'campaigns', campaign.uid), campaignTmp);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  removeTeamFromCampaign: async (id, team_uid) => {
    const docRef = await getDoc(doc(db, 'campaigns', id));
    const docTmp = docRef.data();
    const campaignTmp = docTmp.team_uids || docTmp.relations;
    delete campaignTmp[team_uid];

    docTmp.team_uids
      ? await updateDoc(doc(db, 'campaigns', id), {
          team_uids: campaignTmp,
        })
      : await updateDoc(doc(db, 'campaigns', id), {
          relations: campaignTmp,
        });
  },

  removeUserFromRelationsCampaign: async (id, team_uid, userUid) => {
    const docRef = await getDoc(doc(db, 'campaigns', id));
    const docTmp = docRef.data().relations;
    const newT = docTmp[team_uid].evaluator_uids.filter((t) => t !== userUid);
    docTmp[team_uid].evaluator_uids = newT;
    await updateDoc(doc(db, 'campaigns', id), {
      relations: docTmp,
    });
  },

  addUserIntoRelationsCampaign: async (id, team_uid, userUid) => {
    const docRef = await getDoc(doc(db, 'campaigns', id));
    const docTmp = docRef.data();
    if (!docTmp.relations[team_uid].evaluator_uids.includes(userUid)) {
      docTmp.relations[team_uid].evaluator_uids.push(userUid);
      await updateDoc(doc(db, 'campaigns', id), docTmp);
      return;
    }
  },

  getCampaignWithRelationWithTeam: async () => {
    const q = query(
      collection(db, 'campaigns'),
      where('relation_with_team', '==', true)
    );

    const querySnapshot = await getDocs(q);
    const campaigns = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.data());
      let new_campaign = doc.data();
      new_campaign.uid = doc.id;
      campaigns.push(new_campaign);
    });

    return campaigns;
  },
};
export { CAMPAIGNS };
