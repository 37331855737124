import React from 'react';

export default function Loader() {
  return (
    <div className="loader-container">
      <div className="dot dot-1"></div>
      <div className="dot dot-2"></div>
      <div className="dot dot-3"></div>
      <div className="dot dot-4"></div>
    </div>
  );
}
