import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, CAMPAIGNS, TEAMS } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import OrgaModifyTeamToCampaignModal from '../modals/OrgaModifyTeamToCampaignModal';
import { useTranslation } from 'react-i18next';
import { MissionContext } from '../../hooks';

const handleRemoveTeam = async (campaignUid, teamUid) => {
  try {
    await CAMPAIGNS.removeTeamFromCampaign(campaignUid, teamUid);
  } catch (error) {
    console.log('Error occured when removing team from campaign', error);
  }
};

const displayName = (collab) => {
  const index = collab.indexOf('@');
  return collab.substr(0, index);
};

export default function OrgaConfigurationRow({ relations, campaign, teamUid }) {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const { mission } = useContext(MissionContext);
  const [collabInfos, setCollabInfos] = useState(null);
  const [team, setTeam] = useState(null);
  const [targetTeam, setTargetTeam] = useState(null);
  const [modifyTeam, setModifyTeam] = useState(false);

  useEffect(async () => {
    let teamTmp;
    let targetTeamTmp;

    teamTmp = await TEAMS.getTeam(relations.team_uid);
    targetTeamTmp = await TEAMS.getTeam(relations.target_team_uid);
    setTeam(teamTmp);
    setTargetTeam(targetTeamTmp);
    const functions = getFunctions();
    const fetchUserData = httpsCallable(functions, 'fetchUserData');
    const response = await fetchUserData({
      requesterUID: currentUser.user.uid,
      requestedClientUID: mission.client_uid,
      requestedUserUIDS: relations.evaluator_uids,
    });

    // setTeam([]);
    setCollabInfos(response.data);
  }, []);

  const actions = () => {
    return (
      <div className="border-black w-2/5 flex items-center justify-around flex-wrap">
        <button
          onClick={() => setModifyTeam(true)}
          className="button bg-blue-300 hover:bg-blue-600"
        >
          {t('modify')}
        </button>

        <button
          onClick={() => handleRemoveTeam(campaign.uid, teamUid)}
          className="button bg-red-300 hover:bg-red-600"
        >
          {t('delete')}
        </button>
      </div>
    );
  };

  const buildCollabList = () => {
    if (collabInfos && team) {
      console.log(relations);
      console.log(collabInfos);
      return collabInfos.map((collabInfo, index) => {
        return (
          <li
            className={
              team.manager_uid &&
              targetTeam.managers_uid &&
              (team.managers_uid.includes(collabInfo.uid) ||
                targetTeam.managers_uid.includes(collabInfo.uid))
                ? 'my-1 font-bold'
                : 'my-1'
            }
            key={index}
          >
            {displayName(collabInfo.email)}
            {team.collab_uids.includes(collabInfo.uid) && (
              <span className="text-xs ml-2 font-normal">{team.team}</span>
            )}
            {targetTeam.collab_uids.includes(collabInfo.uid) &&
              targetTeam.uid !== team.uid && (
                <span className="text-xs ml-2 font-normal">
                  {targetTeam.team}
                </span>
              )}
          </li>
        );
      });
    } else {
      return <p>Building collaborator list ...</p>;
    }
  };

  return (
    <>
      <OrgaModifyTeamToCampaignModal
        setOpen={setModifyTeam}
        open={modifyTeam}
        collaborators={collabInfos}
        setCollabInfos={setCollabInfos}
        campaignUid={campaign.uid}
        relation={relations}
      />
      <div className="flex border border-black py-2">
        <div className="border-r-2 w-1/5  border-black  flex items-center justify-center">
          <p>{team && team.team}</p>
        </div>
        <div className="border-r-2 w-1/5  border-black  flex items-center justify-center">
          <p>{targetTeam && targetTeam.team}</p>
        </div>
        <ul className="border-r-2 w-2/5 border-black flex flex-col items-center justify-center">
          {buildCollabList()}
        </ul>
        {actions()}
      </div>
    </>
  );
}
