import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translationEn } from './translationEn';
import { translationFr } from './translationFr';

export const i18next = (version) => {
  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: translationEn },
      fr: { translation: translationFr },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    lng: version,
    fallbackLng: 'en',
  });
};
