export const translationEn = {
  welcome: 'Coaching missions',
  newMission: 'New Mission',
  newTeam: 'new team',
  existingTeam: 'existing teams',
  missionName: 'Mission title',
  newCampaign: 'New Campaign',
  noMission: 'No Mission',
  status: 'Status',
  details: 'Details',
  unknowClient: 'Unknown client',
  unknowMission: 'Unknown mission',
  finishMission: 'Close Mission',
  noCampaignYet: 'No campaign yet',
  addTeam: 'Add Team',
  teamName: 'Team name',
  memberEmail: 'Member email',
  findMember: 'Find a member',
  memberList: 'Team list',
  selectTeam: 'Select team to add to campaign',
  add: 'Add',
  team: 'Team',
  collaborators: 'collaborators',
  modify: 'Modify',
  delete: 'Delete',
  responses: 'Responses',
  generateCard: 'Generate Card',
  seeCard: 'See Card',
  noCardAvailable: 'No Card Available',
  draft: 'Draft',
  inProgress: 'In Progress',
  ended: 'Ended',
  launchCampaign: 'Launch Campaign',
  stopCampaign: 'Stop Campaign',
  deleteCampaign: 'Delete Campaign',
  campaignName: 'Campaign name',
  create: 'Create',
  close: 'close',
  surveyClose: 'Survey Close',
  surveys: 'Questionnaires',
  login: 'sign in',
  logout: 'sign out',
  mySurveys: 'My Surveys',
  concernedTeams: 'Teams',
  go: 'Go',
  waiting: 'Waiting',
  answered: 'Answered',
  noCard: 'No card',
  average: 'Average',
  standardDeviations: 'Standard deviations',
  categories: 'Categories',
  meaning: 'Meaning',
  energy: 'Energy',
  time: 'Time',
  space: 'Space',
  emotion: 'Emotion',
  organization: 'Organization',
  visualization: 'Pro Visualization of team',
  visualizationOrga: 'Pro Visualization of organization',
  surveyFrom: 'Questionnaire from ',
  yourAnswer:
    'Your answers are awaited within the framework of the accompaniment of ',
  toImprove: ' to improve the relational performance of your team.',
  start: 'Start',
  thanksTo: 'Thank you for your participation',
  goBack: 'Go back',
  theMethod: 'The Methodology P.R.O',
  text1:
    "Our diagnostic solution, Optimal Relational Performance, objectively measures the state of the relationship through 5 dimensions. By focusing on the relationships between teams, departments or departments, you visualize the entire relational fabric of your organization. It then becomes possible to intervene / to vary parameters in the relationship. Our tools are designed to visualize the data supporting your organization's relationships. It then becomes possible to decide which accompaniments to trigger for which objectives and above all to measure the impacts as they go along. Because it focuses on the performance of the relationship, the P.R.O stands out from other individual-oriented models by giving the organization the capacity for action essential to its transformation challenges.",
  glossary: 'Glossary',
  relationFabric: 'The relational fabric',
  text2:
    'Depending on the completion rate of the questionnaires, you have access to the representation of the relational fabric of your organization. Each of the relational units is represented by a circle identified by its acronym.',
  relation: 'Relation',
  text3:
    "We consider the relationship as the subject allowing to sustainably improve the performance of organizations. Rather than focusing on the individual or on the unit, we promote the observation of what is produced interaction and this in an objective way. Our tools are designed to allow visualization of the data supporting the your organization's relationships. It therefore becomes possible to decide which accompaniments to trigger for what objectives and above all with what impact as measure",
  passwordLenght: 'Password must be at least 8 characters long',
  passwordUppercase:
    'Password must contain at least one uppercase letter (A-Z)',
  passwordLowercase:
    'Password must contain at least one lowercase letter (a-z)',
  passwordDigit: 'Password must contain at least one digit (0-9)',
  passwordSpecial:
    'Password must contain at least one special character (!@#$&*)',
  profile: "User's profile",
  changePassword: 'Change password',
  resettingPassword: 'Try resetting your password again',
  resetPassword: 'Reset Password',
  currency: 'time',
  words: 'The most used words are',
  happyPro: 'Happy PRO!',
  verticilleTeam: 'The Verticille team',

  welcomeToCollab1:
    'Hello and welcome to your P.R.O space. You will find here all the questionnaires related to the teams that you belong to (with which you work). As a reminder, you will have to provide answers based on your perception of your RELATIONSHIPS and not on the people or teams themselves.',
  welcomeToCollab2:
    'Once all team members have completed the questionnaire, you will have an overview of  the Visual representation of relation performance of your team. This diagram is the representation of all your relationships to the team. We will use this diagram in order to assess the evolution of these relationships as well as to attain your overall relationship objectives.',
  welcomeToAdmin1:
    'Hello and welcome to your P.R.O space. You will find here all the questionnaires related to parameters of your team. As a reminder, you will have to provide answers based on your perception of your RELATIONSHIPS and not on the people or teams themselves.',
  welcomeToAdmin2:
    'Once all team members have completed the questionnaire, you will have an overview of the Visual representation of relation performance of your team. This diagram is the representation of all your relationships to the team. We will use this diagram in order to assess the evolution of these relationships as well as to attain your overall relationship objectives.',
  welcomeToManagingPartner1:
    'Hello and welcome to your P.R.O space. You will find here all the questionnaires related to parameters of your team. As a reminder, you will have to provide answers based on your perception of your RELATIONSHIPS and not on the people or teams themselves.',
  welcomeToManagingPartner2:
    'Once all team members have completed the questionnaire, you will have an overview of the Visual representation of relation performance of your team. This diagram is the representation of all your relationships to the team. We will use this diagram in order to assess the evolution of these relationships as well as to attain your overall relationship objectives.',
  welcomeToCoach1:
    'Hello and welcome to your P.R.O space. You will find here all the questionnaires related to the clients that you follow. As a reminder, you will have to provide answers based on your perception of your RELATIONSHIPS and not on the people or teams themselves.',
  welcomeToCoach2:
    'Once all team members have completed the questionnaire, you will have an overview of the Visual representation of relation performance of your team. This diagram is the representation of all your relationships to the team. We will use this diagram in order to assess the evolution of these relationships as well as to attain your overall relationship objectives.',
  seenByDefault: 'Seen by default',
  targetTeam: 'Team target',
  minMax: 'Pole gap (min | max)',
  effort1: 'I CAN do anything for the success of the project',
  effort2: 'I HAVE TO do something for the success of the project',
  effort3: 'I WANT to do something for the success of the project',
  efforts: 'Efforts',
};
