import React, { useState, useEffect, useContext } from 'react';

import { MissionContext } from '../../hooks';
import { TEAMS, CAMPAIGNS } from '../../firebase';

import Modal from '../../layout/Modal';
import TeamList from '../modal-components/TeamList';
import { useTranslation } from 'react-i18next';

export default function AddTeamToCampaignModal({ open, setOpen, campaign }) {
  const { t } = useTranslation();

  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const { mission } = useContext(MissionContext);

  useEffect(async () => {
    const teamsTmp = await TEAMS.getClientTeams(mission.client_uid);
    setTeams(teamsTmp);
  }, [selectedTeam]);

  const handleSubmit = async () => {
    if (selectedTeam !== '' || selectedTeam) {
      await CAMPAIGNS.addTeamToCampaign(campaign.uid, selectedTeam.uid);
      setSelectedTeam(null);
      setOpen(false);
    }
  };

  const teamList = () => {
    if (teams) {
      return teams.map((team, index) => {
        return (
          <div key={index} className="flex items-center mb-4">
            <TeamList
              setSelectedTeam={setSelectedTeam}
              selectedTeam={selectedTeam}
              team={team}
            />
          </div>
        );
      });
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex justify-between items-end mb-4">
        <p className="text-greenBaaS font-bold text-lg">{t('selectTeam')}</p>
      </div>

      <div className="border rounded-2xl shadow p-4 mb-4">{teamList()}</div>

      <button
        onClick={() => handleSubmit(selectedTeam)}
        className="w-32 py-2 bg-greenButtonBaaS rounded-2xl hover:bg-greenBaaS text-white"
      >
        {t('add')}
      </button>
    </Modal>
  );
}
