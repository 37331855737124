import { useState, useEffect } from 'react';
import { query, collection, where, onSnapshot } from 'firebase/firestore';
import { db, TEAMS } from '../../firebase';

export default function DisplayTeams({
  clientUid,
  setSelectedTeam,
  selectedTeam,
}) {
  const [teams, setTeams] = useState(null);
  const [newTeam, setNewTeam] = useState('');

  useEffect(() => {
    const q = query(
      collection(db, 'teams'),
      where(`client_uid`, '==', clientUid)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const teamsTmp = [];
      querySnapshot.forEach((doc) => {
        const team = doc.data();
        team.uid = doc.id;
        teamsTmp.push(team);
      });

      setTeams(teamsTmp);
    });

    return () => unsubscribe();
  }, []);

  const handleCreateTeam = async (name, clientUid) => {
    await TEAMS.createTeam({
      name: newTeam,
      clientId: clientUid,
      collabUids: [],
    });
    setNewTeam('');
  };

  const handleDeleteTeam = async (id) => {
    if (confirm('Are you sure you want to Delete this team?')) {
      // Save it!
      await TEAMS.deleteTeam(id);
    }
  };

  return (
    <div>
      {teams &&
        teams.map((t) => {
          return (
            <div key={t.uid} className="flex justify-between w-full p-3">
              <p
                onClick={() => setSelectedTeam(t)}
                className={
                  selectedTeam && selectedTeam.uid === t.uid
                    ? 'font-bold'
                    : 'cursor-pointer'
                }
              >
                {t.team}
              </p>
              <button onClick={() => handleDeleteTeam(t.uid)}>X</button>
            </div>
          );
        })}
      <div className="border bg-green-100 p-4">
        <p>Add New team</p>
        <input
          className="border-2 rounded p-2 mb-4 h-10 w-full"
          placeholder="New Team Name"
          type="text"
          value={newTeam}
          onChange={(e) => setNewTeam(e.target.value)}
        />
        <button
          onClick={() => handleCreateTeam(newTeam, clientUid)}
          className="button w-full"
        >
          Add
        </button>
      </div>
    </div>
  );
}
