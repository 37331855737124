import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../helpers/capitalize';

export default function Dropdown({
  element,
  setElement,
  dropdownTitle,
  id,
  handler,
}) {
  const [selection, setSelection] = useState('');

  useEffect(() => {
    if (handler !== '') setSelection('');
  }, [handler]);

  setTimeout(() => {
    dropdownHandler();
    document.getElementById(`${id}-dropdown`).classList.remove('opacity-0');
  }, 1000);

  let flag = false;
  const dropdownHandler = () => {
    if (!flag) {
      document.getElementById(`${id}-dropdown`).classList.add('hidden');
      document.getElementById(`${id}-open`).classList.add('hidden');
      document.getElementById(`${id}-close`).classList.remove('hidden');
      flag = true;
    } else {
      document.getElementById(`${id}-dropdown`).classList.remove('hidden');
      document.getElementById(`${id}-open`).classList.remove('hidden');
      document.getElementById(`${id}-close`).classList.add('hidden');
      flag = false;
    }
  };

  const handleSelect = (elt) => {
    dropdownHandler();
    setSelection(elt.name);
    setElement(elt);
  };

  const elementList = () => {
    if (element) {
      return element.map((elt, index) => {
        return (
          <p
            key={index}
            onClick={() => handleSelect(elt)}
            className="hover:text-green-400 text-sm cursor-pointer mb-2"
          >
            {capitalizeFirstLetter(elt.name)}
          </p>
        );
      });
    }
  };

  return (
    <div className="relative">
      <button
        className="focus:outline-none border focus:ring-2 focus:ring-offset-1 focus:ring-greenBaaS rounded-2xl focus:bg-gray-100 w-64 p-4 shadow  bg-white text-sm font-medium leading-none text-gray-800 flex items-center justify-between cursor-pointer"
        onClick={dropdownHandler}
      >
        {selection
          ? capitalizeFirstLetter(selection)
          : `Selectionner ${dropdownTitle}`}
        <div>
          <div className="hidden" id={`${id}-open`}>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z"
                fill="#1F2937"
              />
            </svg>
          </div>
          <div id={`${id}-close`}>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z"
                fill="#1F2937"
              />
            </svg>
          </div>
        </div>
      </button>

      <div
        className="w-64 mt-2 p-4 border rounded-2xl absolute bg-white shadow opacity-0"
        id={`${id}-dropdown`}
      >
        {elementList()}
      </div>
    </div>
  );
}
