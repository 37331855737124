/* eslint-disable no-undef */
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Efforts({ efforts }) {
  const { t } = useTranslation();

  return (
    <div className="text-xl">
      <p className="text-2xl  text-center mt-10 mb-10">{t('efforts')} </p>
      <p>
        {t('effort1')} = {efforts[0].value}
      </p>
      <p>
        {t('effort2')} = {efforts[1].value}
      </p>
      <p>
        {t('effort3')} = {efforts[2].value}
      </p>
    </div>
  );
}
