import React from 'react';
import MissionRow from './MissionRow';
import MissionHeader from './MissionHeader';
import { useTranslation } from 'react-i18next';

export default function ListMission({ missions }) {
  const { t } = useTranslation();
  const listMissionsDisplay = () => {
    if (missions) {
      return (
        <div className="border-2 border-black">
          <MissionHeader />
          {missions.map((mission, index) => (
            <MissionRow key={index} mission={mission} />
          ))}
        </div>
      );
    } else {
      return <>{t('noMission')}</>;
    }
  };

  return <>{listMissionsDisplay()}</>;
}
