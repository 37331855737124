import React from 'react';
import LogLayout from '../layout/LogLayout';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../firebase';
import { isPasswordStrong } from '../helpers/password_helpers';

export default function Authentification() {
  const { signup } = useContext(AuthContext);
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const goToLogin = (e) => {
    e.preventDefault;
    navigate('/login');
  };

  const createAccount = async (e, cred) => {
    e.preventDefault();

    if (!isPasswordStrong(cred.password)) {
      console.log('Password is not strong enough');

      setError(`
Password must be at least 8 characters long
Password must contain at least one uppercase letter (A-Z)
Password must contain at least one lowercase letter (a-z)
Password must contain at least one digit (0-9)
Password must contain at least one special character (!@#$&*)
      `);
    } else {
      let success = await signup(cred.email, cred.password);
      if (success) {
        navigate(`/welcome`);
      } else {
        setError('An error occured during sign up');
      }
    }
  };

  return (
    <>
      <LogLayout>
        <h1 className="text-[36px] text-greenBaaS text-bold mt-4 mb-10">
          Bienvenue
        </h1>
        <form
          onSubmit={(e) => createAccount(e, credentials)}
          className="w-2/3 flex flex-col items-center"
        >
          <input
            name="email"
            placeholder="john.doe@example.com"
            autoComplete="off"
            type="text"
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
            value={credentials.email}
            className=" bg-white w-full py-4 px-2 mb-8  border shadow-sm  rounded-xl"
          />

          <input
            name="email"
            placeholder="password"
            autoComplete="off"
            type="password"
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            value={credentials.password}
            className=" bg-white w-full py-4 px-2   border shadow-sm  rounded-xl"
          />

          <button
            type="submit"
            className="bg-greenButtonBaaS text-white w-2/3 py-4 hover:bg-greenBaaS rounded-[100px] mb-2 mt-10"
          >
            inscription
          </button>
        </form>
        <p
          onClick={goToLogin}
          className="cursor-pointer p-2 rounded-xl text-center bg-white"
        >
          se connecter
        </p>
        <p className="text-xs h-8 text-red-600 mb-10 whitespace-pre-wrap">
          {error}
        </p>
      </LogLayout>
    </>
  );
}
