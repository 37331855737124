import React, { useContext } from 'react';
import { AuthContext } from '../../firebase';
import SurveyItem from './SurveyItem';
import OrgaSurveyItem from './OrgaSurveyItem';
import SurveyHeader from './SurveyHeader';
import OrgaSurveyHeader from './OrgaSurveyHeader';

export default function ListSurvey({ surveys }) {
  const { currentUser } = useContext(AuthContext);
  // const [teamSurveys, setTeamSurveys] = useState([]);

  const teamList = () => {
    const orgaSurvey = surveys.filter((s) => {
      return s.type === 'organisation';
    });

    console.log(orgaSurvey);

    const teamSurveysTmp = surveys.filter((s) => {
      return s.type === 'team';
    });

    if (teamSurveysTmp.length !== 0) {
      return teamSurveysTmp.map((survey, index) => {
        if (survey.status !== 3)
          return <SurveyItem key={index} survey={survey} user={currentUser} />;
      });
    } else {
      return false;
    }
  };

  const orgaList = () => {
    const orgaSurveyTmp = surveys.filter((s) => {
      return s.type === 'organisation';
    });

    if (orgaSurveyTmp.length !== 0) {
      return orgaSurveyTmp.map((survey, index) => {
        if (survey.status !== 3)
          return (
            <OrgaSurveyItem key={index} survey={survey} user={currentUser} />
          );
      });
    } else {
      return false;
    }
  };

  return (
    <>
      <div>
        {teamList() && (
          <div>
            <p>Team</p>
            <div className="border-2 border-black min-h-[100px] rounded mb-8">
              <SurveyHeader />
              {teamList()}
            </div>
          </div>
        )}
        {orgaList() && (
          <div>
            <p>Organisation</p>
            <div className="border-2 border-black min-h-[100px] rounded">
              <OrgaSurveyHeader />
              {orgaList()}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
