import React, { useContext } from 'react';
import TeamRunningHeader from '../team/TeamRunningHeader';
import OrgaRunningHeader from '../team/OrgaRunningHeader';
import OrgaRunningRow from '../team/OrgaRunningRow';
import TeamRunningRow from '../team/TeamRunningRow';
import { MissionContext } from '../../hooks';

export default function CampaignTeamRunning({ campaign }) {
  const { mission } = useContext(MissionContext);

  const displayOrga = () => {
    return (
      <>
        <OrgaRunningHeader />
        {Object.values(campaign.relations).map((survey, index) => (
          <OrgaRunningRow key={index} surveyId={survey} />
        ))}
      </>
    );
  };

  const displayTeam = () => {
    return (
      <>
        <TeamRunningHeader />
        {Object.values(campaign.team_uids).map((survey, index) => (
          <TeamRunningRow key={index} surveyId={survey} />
        ))}
      </>
    );
  };

  const displayRunningRow = () => {
    if (mission.type === 'team' && campaign) {
      return displayTeam();
    } else {
      return displayOrga();
    }
  };

  return (
    <div className="mb-8 border-2 border-black min-h-[100px] rounded">
      {campaign && displayRunningRow()}
    </div>
  );
}
