import React from 'react';
import LogLayout from '../layout/LogLayout';
import { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../firebase';

import { isPasswordStrong } from '../helpers/password_helpers';
import { useTranslation } from 'react-i18next';

export default function NewPassword() {
  const { t } = useTranslation();
  const [wrongPasswordError, setWrongPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordValidated, setPasswordValidated] = useState(false);
  const { changePassword, logout, currentUser, signin } =
    useContext(AuthContext);
  const navigate = useNavigate();

  window.addEventListener('beforeunload', async (e) => {
    e.preventDefault();
    await signin(currentUser.user.email, newPassword);
    if (!passwordValidated) logout();
  });

  console.log(currentUser);

  const changePasswordHandler = async (e) => {
    e.preventDefault();
    if (!isPasswordStrong(newPassword)) {
      console.log('Password is not strong enough');

      setWrongPasswordError(`
        ${t('passwordLenght')}
        ${t('passwordUppercase')}
        ${t('passwordLowercase')}
        ${t('passwordDigit')}
        ${t('passwordSpecial')}
          `);
    } else {
      await changePassword(newPassword)
        .then(async () => {
          setPasswordValidated(true);
        })
        .then(() => {
          setTimeout(() => {
            navigate('/welcome');
          });
        });
    }
  };

  const buildPageLayout = () => {
    return (
      <div className="p-8">
        <h1 className="text-[38px] font-bold text-center text-greenBaaS mt-10 mb-5">
          Create your own password
        </h1>
        <form
          className="w-full flex flex-col"
          onSubmit={(e) => changePasswordHandler(e)}
        >
          <input
            name="password"
            placeholder="New password"
            autoComplete="off"
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
            className=" bg-white w-full py-4 px-2 mb-10 border shadow-sm  rounded-xl"
          />

          <button
            type="submit"
            className="cursor-pointer  p-2  text-center text-white bg-greenButtonBaaS hover:bg-greenBaaS rounded-[100px]"
          >
            {t('create')}
          </button>
          <p className="text-xs h-8 text-red-600 mt-5 whitespace-pre-wrap">
            {wrongPasswordError}
          </p>
        </form>
      </div>
    );
  };

  return (
    <>
      <LogLayout>{buildPageLayout()}</LogLayout>
    </>
  );
}
