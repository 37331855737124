import React from 'react';

import Layout from '../layout/Layout';

export default function Campaign() {
  return (
    <>
      <Layout>
        <div className="h-screen w-full flex justify-center items-center">
          Votre derniere campage de la mission
        </div>
      </Layout>
    </>
  );
}
