import React, { useContext, useEffect, useState } from 'react';

import { AuthContext, MISSIONS, CLIENTS } from '../firebase';
import { createClient, createUser } from '../firebase/cloud-function';
import Layout from '../layout/Layout';
import Dropdown from '../components/tools/Dropdown';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const type = [
  { name: 'team', uid: '1' },
  { name: 'organisation', uid: '2' },
  { name: 'team & organisation', uid: '2' },
];

export default function CreateMission() {
  const { t } = useTranslation();

  const [clients, setClients] = useState();
  const [client, setClient] = useState(null);
  const [types, setTypes] = useState(null);
  const [newClient, setNewClient] = useState('');
  const [clientName, setClientName] = useState('');
  const [newClientVerification, setNewClientVerification] = useState('');
  const [adminList, setAdminList] = useState([]);
  const [partenaireList, setPartenaireList] = useState([]);
  const [partenaireName, setPartenaireName] = useState('');
  const [missionTitle, setMissionTitle] = useState('');

  let navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  useEffect(async () => {
    const clients = await CLIENTS.getClientsByAdmin(currentUser.user.uid);
    setClients(clients);
  }, []);

  useEffect(() => {
    setNewClient('');
    setNewClientVerification('');
    setAdminList([]);
    setClientName('');
  }, [client]);

  useEffect(() => {
    setClient(null);
  }, [clientName]);

  const handlerClient = async (name, admins) => {
    const clientTmp = await createClient({
      name: name,
      admins: admins,
    });
    return clientTmp.data;
  };

  const createMission = async () => {
    let mission = {
      coach_uid: currentUser.user.uid,
      type: types.name,
      title: missionTitle,
      managing_partenair_uids: [],
    };
    if (clientName && adminList.length !== 0 && !client) {
      const response = await handlerClient(clientName, adminList);
      mission.client_uid = response;
    } else {
      mission.client_uid = client.uid;
    }

    if (partenaireList.length !== 0) {
      mission.managing_partenair_uids = await Promise.all(
        partenaireList.map(async (m) => {
          const response = await createUser({ email: m });
          await CLIENTS.createManagingPartner(
            mission.client_uid,
            response.data
          );
          return response.data;
        })
      );
    }

    if (types.name !== 'team & organisation') {
      await MISSIONS.createMission(mission).then((response) => {
        navigate(`/missions/${response}`);
      });
    } else {
      await MISSIONS.createMissionTeamOrga(mission).then(() => {
        navigate(`/missions/`);
      });
    }
  };

  const addAdminToList = async (admin) => {
    if (
      newClient === newClientVerification &&
      (newClient || newClientVerification)
    ) {
      const adminTmp = adminList.filter((m) => m !== admin);
      setAdminList([...adminTmp, admin]);
    } else {
      alert(
        'Les emails ne sont pas identiques ou les champs ne sont pas remplis '
      );
    }
  };

  const addPartenaireToCLient = async (email) => {
    const newPartenaire = partenaireList.filter((m) => m !== email);
    setPartenaireList([...newPartenaire, email]);
  };

  return (
    <>
      <Layout>
        <h1 className="text-[38px] font-bold text-greenBaaS mt-10 mb-16">
          {t('newMission')}
        </h1>

        <div className="flex flex-col w-1/2 mx-auto">
          <div className="flex flex-col items-center justify-between mb-16 border p-2">
            <div className="flex w-full items-center justify-between mb-4">
              <label className="mr-10" htmlFor="mission">
                {t('missionName')}:
              </label>
              <input
                className="h-12 w-64 border rounded-xl pl-4 shadow"
                type="text"
                name="mission"
                id="mission"
                placeholder={t('missionName')}
                value={missionTitle}
                onChange={(e) => setMissionTitle(e.target.value)}
              ></input>
            </div>
            <div className="flex w-full items-center justify-between mb-4">
              <label className="mr-8" htmlFor="client">
                Selectionner un client:
              </label>
              <Dropdown
                element={clients}
                setElement={setClient}
                dropdownTitle="du client"
                id="client"
                handler={newClient}
              />
            </div>
            <p>👇 Non Obligatoire 👇</p>
            <div className="flex justify-between items-center w-full mb-4">
              <label className="mr-8" htmlFor="client">
                Ajouter un partenaire administrateur:
              </label>
              <input
                className="h-12 w-64 border rounded-xl pl-4 shadow"
                type="email"
                name="email"
                id="name"
                placeholder="Nom du client"
                value={partenaireName}
                onChange={(e) => setPartenaireName(e.target.value)}
              ></input>
            </div>
            <button
              onClick={() => addPartenaireToCLient(partenaireName)}
              className="button"
            >
              Ajouter Admin
            </button>

            {partenaireList.length !== 0 && (
              <div>
                <p>Partenaires</p>
                <ul>
                  {partenaireList.map((t, index) => {
                    return <li key={index}>{t}</li>;
                  })}
                </ul>
              </div>
            )}
          </div>

          <div className="flex flex-col items-center justify-between mb-16 border p-2">
            <p>Ou ajouter un nouveau client</p>
            <div className="flex justify-between items-center w-full mb-4">
              <label className="mr-8" htmlFor="client">
                Nouveau Client:
              </label>
              <input
                className="h-12 w-64 border rounded-xl pl-4 shadow"
                type="text"
                name="email"
                id="name"
                placeholder="Nom du client"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              ></input>
            </div>
            <div className="flex justify-between items-center w-full mb-4">
              <label className="mr-8" htmlFor="client">
                Email Administrateur:
              </label>
              <input
                className="h-12 w-64 border rounded-xl pl-4 shadow"
                type="email"
                name="email"
                id="email"
                placeholder="Email admin client"
                value={newClient}
                onChange={(e) => setNewClient(e.target.value)}
              ></input>
            </div>
            <div className="flex justify-between items-center w-full mb-4">
              <label className="mr-8" htmlFor="client">
                Confirmer l'email Admin du client:
              </label>
              <input
                className="h-12 w-64 border rounded-xl pl-4 shadow"
                type="email"
                name="email"
                id="email"
                placeholder="Confirmation Email Admin"
                value={newClientVerification}
                onChange={(e) => setNewClientVerification(e.target.value)}
              ></input>
            </div>
            <button
              onClick={() => addAdminToList(newClient)}
              className="button"
            >
              Ajouter Admin
            </button>
            {adminList.length !== 0 && (
              <div>
                <p>Admins</p>
                <ul>
                  {adminList.map((t, index) => {
                    return <li key={index}>{t}</li>;
                  })}
                </ul>
              </div>
            )}
          </div>
          <div className="flex items-center justify-between mb-16">
            <label className="mr-8" htmlFor="type">
              Selectionner le type de mission:
            </label>
            <Dropdown
              element={type}
              setElement={setTypes}
              dropdownTitle="du type"
              id="type"
            />
          </div>

          <button onClick={() => createMission()} className="button">
            Créer
          </button>
        </div>
      </Layout>
    </>
  );
}
