import React, { useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import TypeFormSurvey from '../components/TypeFormSurvey';
import { useParams } from 'react-router-dom';
import { SURVEYS, TEAMS } from '../firebase';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function Survey() {
  const { t } = useTranslation();
  const [start, setStart] = useState(false);
  const params = useParams();
  const [survey, setSurvey] = useState(null);
  const [team, setTeam] = useState(null);
  const [targetTeam, setTargetTeam] = useState(null);

  useEffect(async () => {
    const surveyTmp = await SURVEYS.getSurvey(params.survey_id);
    const teamTmp = await TEAMS.getTeam(surveyTmp.team_uid);
    let targetTeamTmp = null;
    if (surveyTmp.type === 'organisation')
      targetTeamTmp = await TEAMS.getTeam(surveyTmp.target_team_uid);
    setTeam(teamTmp);
    setTargetTeam(targetTeamTmp);
    setSurvey(surveyTmp);
  }, []);

  const displaySurvey = () => {
    if (start) {
      return (
        <>
          <p className="mb-10 text-greenBaaS text-lg font-bold">
            {t('surveyFrom')}
            {survey
              ? moment(new Date(survey.created_at)).format('MMM Do YY')
              : 'loading'}
          </p>
          <div className="h-[600px]  border-2 rounded-2xl shadow">
            <TypeFormSurvey survey={survey} />
          </div>
        </>
      );
    } else {
      return (
        <div className="w-2/3 mx-auto flex flex-col justify-center items-center">
          <p className="mb-10 text-greenBaaS text-lg font-bold">
            {t('surveyFrom')}
            {survey
              ? moment(new Date(survey.created_at)).format('MMM Do YY')
              : 'loading'}
          </p>
          <p className="mb-10 text-center">
            {t('yourAnswer')}
            <span className="text-greenBaaS text-lg font-bold">
              {team ? team.team : 'loading'}
            </span>{' '}
            <span className="text-greenBaaS text-lg font-bold">
              {targetTeam && `for ${targetTeam.team}`}
            </span>{' '}
            {t('toImprove')}
          </p>
          <button
            onClick={() => setStart(true)}
            className="px-8 py-4 bg-greenButtonBaaS hover:bg-greenBaaS text-white rounded-2xl"
          >
            {t('start')}
          </button>
        </div>
      );
    }
  };

  return (
    <>
      <Layout>
        <h1 className="text-[38px] font-bold text-greenBaaS mt-10 mb-20"></h1>
        {displaySurvey()}
      </Layout>
    </>
  );
}
