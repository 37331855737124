import { db } from './index';
import {
  getDoc,
  documentId,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  arrayRemove,
  arrayUnion,
} from 'firebase/firestore';
import { query, where, collection, getDocs } from 'firebase/firestore';
// import { v4 as uuidv4 } from 'uuid';
// import fake_json from '../helpers/fake_map.json';

const TEAMS = {
  getTeam: async (id) => {
    const docRef = await getDoc(doc(db, 'teams', id));
    let new_item = docRef.data();
    new_item.uid = docRef.id;
    return new_item;
  },

  createTeam: async (body) => {
    try {
      const docRef = await addDoc(collection(db, 'teams'), {
        collab_uids: body.collabUids,
        managers_uid: [],
        team: body.name,
        client_uid: body.clientId,
        created_at: Date.now(),
      });

      return docRef.id;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  getClientTeams: async (clientId) => {
    const q = query(
      collection(db, 'teams'),
      where('client_uid', '==', clientId)
    );
    const querySnapshot = await getDocs(q);
    const all = [];
    querySnapshot.forEach((doc) => {
      let new_item = doc.data();
      new_item.uid = doc.id;
      all.push(new_item);
    });

    return all;
  },

  deleteTeam: async (id) => {
    try {
      await deleteDoc(doc(db, 'teams', id));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  addUserInTeam: async (id, userId) => {
    try {
      await updateDoc(doc(db, 'teams', id), {
        collab_uids: arrayUnion(userId),
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  addUserAsManager: async (id, userId) => {
    try {
      await updateDoc(doc(db, 'teams', id), {
        managers_uid: arrayUnion(userId),
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  RemoveUserAsManager: async (id, userId) => {
    console.log('jhel');
    try {
      await updateDoc(doc(db, 'teams', id), {
        managers_uid: arrayRemove(userId),
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  removeUserFromTeam: async (id, userId) => {
    try {
      await updateDoc(doc(db, 'teams', id), {
        collab_uids: arrayRemove(userId),
        managers_uid: arrayRemove(userId),
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  // Pour le moment pas besoin \\
  getTeamBulk: async (teamUIDS) => {
    const q = query(
      collection(db, 'teams'),
      where(documentId(), 'in', teamUIDS)
    );
    const querySnapshot = await getDocs(q);
    const all = [];
    querySnapshot.forEach((doc) => {
      let new_item = doc.data();
      new_item.uid = doc.id;
      all.push(new_item);
    });
    return all;
  },

  getUserSurveys: async (userUID) => {
    console.debug('Searching surveys for user', userUID);
  },
  // Pour le moment pas besoin \\
};

export { TEAMS };
