import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../firebase';
import { useTranslation } from 'react-i18next';

export default function MissionRow() {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);

  const addClientHeader = () => {
    if (currentUser.profile.isCoach()) {
      return (
        <div className="h-full w-1/4 border-r-2 border-black flex items-center justify-center">
          <p>Client</p>
        </div>
      );
    }
  };

  return (
    <div className="h-[48px] flex bg-greyBaaS border-b-2 border-black">
      {addClientHeader()}
      <div className="border-r-2 h-full border-black w-2/4 flex items-center justify-center">
        <p>Mission</p>
      </div>
      <div className="border-r-2 h-full border-black w-2/4 flex items-center justify-center">
        <p>Type</p>
      </div>
      <div className="border-r-2 border-black w-1/4 flex items-center justify-center">
        <p>{t('status')}</p>
      </div>
      <div className="flex w-1/4 items-center justify-center">
        <p>Action</p>
      </div>
    </div>
  );
}
