import { useState } from 'react';
import DisplayAdmin from './DisplayAdmin';
import DisplayManagingPartner from './DisplayManagingPartner';
import DisplayTeams from './DisplayTeams';
import DisplayMember from './DisplayMember';

export default function DisplayClient({ client }) {
  const [selectedTeam, setSelectedTeam] = useState(null);

  return (
    <div key={client.uid} className="border mb-5">
      <div className="flex justify-between">
        <div className="w-1/4 border p-2">
          <DisplayAdmin client={client} />
        </div>
        <div className="w-1/4 border p-2">
          <DisplayManagingPartner client={client} />
        </div>
        <div className="w-1/4 border p-2">
          <DisplayTeams
            setSelectedTeam={setSelectedTeam}
            selectedTeam={selectedTeam}
            clientUid={client.uid}
          />
        </div>
        <div className="w-1/4 border p-2">
          <DisplayMember selectedTeam={selectedTeam} client={client} />
        </div>
      </div>
    </div>
  );
}
