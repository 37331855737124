import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SurveyHeader() {
  const { t } = useTranslation();
  return (
    <div className="h-[48px] flex bg-greyBaaS border-b-2 border-black">
      <div className="border-r-2 h-full border-black w-1/3 flex items-center justify-center">
        <p>{t('concernedTeams')}</p>
      </div>
      <div className="border-r-2 h-full border-black w-1/3 flex items-center justify-center">
        <p>{t('targetTeam')}</p>
      </div>
      <div className="w-2/3 flex justify-around h-full">
        <div className="border-r-2 border-black w-1/2 flex items-center justify-center">
          <p>{t('status')}</p>
        </div>
        <div className="w-1/2 flex items-center justify-center">
          <p>Action(s)</p>
        </div>
      </div>
    </div>
  );
}
