import { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db, CLIENTS } from '../../firebase';

const MissionContext = createContext(undefined);

function MissionProvider({ children, ...props }) {
  const params = useParams();
  const [mission, setMission] = useState(null);
  const [client, setClient] = useState(null);
  // const { currentUser } = useContext(AuthContext);

  const getMission = async (id) => {
    const docRefMission = doc(db, 'missions', id);
    const missionRef = await getDoc(docRefMission);
    let missionTmp;
    if (missionRef.exists()) {
      missionTmp = missionRef.data();
      missionTmp.uid = missionRef.id;
      setMission(missionTmp);
      return missionTmp;
    } else {
      return false;
    }
  };

  useEffect(async () => {
    const missionTmp = await getMission(params.mission_id);
    if (missionTmp) {
      const clientTmp = await CLIENTS.getClient(missionTmp.client_uid);
      setClient(clientTmp);
    }
  }, []);

  return (
    <MissionContext.Provider value={{ mission, client }} {...props}>
      {children}
    </MissionContext.Provider>
  );
}

export { MissionProvider, MissionContext };
