import React from 'react';

export default function DisplayCollaborators({ handleCollab, wordsCloud }) {
  return (
    <div
      className={
        handleCollab && wordsCloud
          ? 'block  shadow rounded-2xl border w-80 bg-white absolute bottom-10 h-auto'
          : 'hidden'
      }
    >
      <img src={wordsCloud} />
    </div>
  );
}
