import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export let langTmp = 'en';
export default function Layout({ children }) {
  return (
    <>
      <Navbar />

      <main className="bg-background lg:px-40 md:px-14 pt-8">{children}</main>

      <Footer />
    </>
  );
}
