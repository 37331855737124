import React from 'react';
// import { getFunctions, httpsCallable } from 'firebase/functions';
// import { useParams } from 'react-router-dom';

import { TEAMS } from '../../firebase';
import Modal from '../../layout/Modal';

export default function AddTeamToCampaignModal({
  open,
  setOpen,
  collaborators,
  setCollaborators,
  team,
}) {
  // const [selectedTeam, setSelectedTeam] = useState(null);
  const handleDelete = async (userUid) => {
    await TEAMS.removeUserFromTeam(team.uid, userUid);

    const collabTmp = collaborators.filter((t) => t.uid !== userUid);
    setCollaborators(collabTmp);
    setOpen(false);
  };

  const teamList = () => {
    if (collaborators) {
      return collaborators.map((user, index) => {
        return (
          <div key={index} className="flex items-center justify-between mb-4">
            <p>{user.email}</p>{' '}
            <button
              onClick={() => handleDelete(user.uid)}
              className="text-white rounded-2xl py-1 px-4  bg-red-500 hover:bg-red-800"
            >
              supprimer
            </button>
          </div>
        );
      });
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="border rounded-2xl shadow p-4 mb-8">{teamList()}</div>
    </Modal>
  );
}
