import React from 'react';
import { MissionProvider } from '../hooks';
import Layout from '../layout/Layout';
import CampainListCoach from '../components/coach-dashboard/CampainListCoach';
import MissionTopBar from '../components/mission/MissionTopBar';

export default function MissionDetails() {
  return (
    <>
      <Layout>
        <MissionProvider>
          <MissionTopBar />
          <CampainListCoach />
        </MissionProvider>
      </Layout>
    </>
  );
}
