import React from 'react';

/**
 * Build the tooltip for an edge
 * @param {*} edge Edge from cytoscape component
 * @returns Tooltip for the specifed edge
 */

const RelationTooltipOrgaTeamOrga = (edge, lang) => {
  var dimData = edge.data('dimensions');
  var teamsResultsData = edge.data('teams_results');
  var type = edge.data('type');

  let sourceName = edge.source().data('name');
  let targetName = edge.target().data('name');

  const dimLines = [];

  const displayDimensionKey = (key) => {
    let dimension = '';
    switch (key) {
      case 'meaning':
        lang === 'fr' ? (dimension = 'Sens') : (dimension = 'Meaning');
        break;
      case 'energy':
        lang === 'fr' ? (dimension = 'Energie') : (dimension = 'Energy');
        break;
      case 'time':
        lang === 'fr' ? (dimension = 'Temps') : (dimension = 'Time');
        break;
      case 'space':
        lang === 'fr' ? (dimension = 'Espace') : (dimension = 'Space');
        break;
      case 'emotion':
        lang === 'fr' ? (dimension = 'Emotion') : (dimension = 'Emotion');
        break;
    }
    return dimension;
  };

  dimData.forEach(function (dim) {
    let bgColor;
    switch (dim.key) {
      case 'meaning':
        bgColor = '#4e8cff';
        break;
      case 'energy':
        bgColor = '#fddc70';
        break;
      case 'time':
        bgColor = '#9177ff';
        break;
      case 'space':
        bgColor = '#14c1b2';
        break;
      case 'emotion':
        bgColor = '#f16c6c';
        break;
    }
    const barValue = dim.value * 10;
    dimLines.push(
      <li className="flex justify-between items-center" key={dim.key}>
        <div className="w-1/4">
          <p className="text-black text-capitalize ">
            {displayDimensionKey(dim.key)}
          </p>
        </div>

        <div className="h-2 w-2/4 border border-black rounded-2xl">
          <div
            style={{ width: barValue + '%', backgroundColor: bgColor }}
            className="h-full rounded-2xl"
          ></div>
        </div>

        <p className="text-right w-10   text-black">{dim.value}</p>
      </li>
    );
  });

  teamsResultsData.forEach(function (team, index) {
    dimLines.push(
      <div key={index} className="text-center font-md">
        {team.name}
      </div>
    );
    team.results.forEach(function (result) {
      let bgColor;
      switch (result.key) {
        case 'meaning':
          bgColor = '#4e8cff';
          break;
        case 'energy':
          bgColor = '#fddc70';
          break;
        case 'time':
          bgColor = '#9177ff';
          break;
        case 'space':
          bgColor = '#14c1b2';
          break;
        case 'emotion':
          bgColor = '#f16c6c';
          break;
      }

      const barValue = result.value * 10;
      dimLines.push(
        <li className="flex justify-between items-center" key={result.key}>
          <div className="w-1/4">
            <p className="text-black text-capitalize ">
              {displayDimensionKey(result.key)}
              {/* {capitalizeFirstLetter(result.key)} */}
            </p>
          </div>

          <div className="h-2 w-2/4 border border-black rounded-2xl">
            <div
              style={{ width: barValue + '%', backgroundColor: bgColor }}
              className="h-full rounded-2xl"
            ></div>
          </div>

          <p className="text-right w-10   text-black">{result.value}</p>
        </li>
      );
    });
  });

  const displayCollabTeam = () => {
    return teamsResultsData.map((t, index) => {
      return (
        <span key={index} className="text-greenBaaS font-bold">
          {index === 0 ? t.name : ', ' + t.name}
        </span>
      );
    });
  };

  const displayFootage = () => {
    if (type === 'organisation') {
      return (
        <>
          <p className="mb-1 text-center">
            Footage between
            <span className="text-greenBaaS font-bold"> {targetName} </span>
            and
            <span className="text-greenBaaS font-bold"> {sourceName} </span>
            by {displayCollabTeam()}
          </p>
        </>
      );
    }
  };

  return (
    <div className="border bg-greyBaaS p-4 shadow rounded-2xl w-[300px]">
      {displayFootage()}
      <div>
        <ul className="flex flex-col">{dimLines}</ul>
      </div>
    </div>
  );
};

export default RelationTooltipOrgaTeamOrga;
