import { db } from './index';
import { v4 as uuidv4 } from 'uuid';
import {
  doc,
  setDoc,
  updateDoc,
  getDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  where,
  getDocs,
  query,
  collection,
  orderBy,
} from 'firebase/firestore';

const MISSIONS = {
  getMission: async (id) => {
    try {
      const docRef = await getDoc(doc(db, 'missions', id));
      return docRef.data();
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  getCoachMissions: async (id) => {
    const q = query(collection(db, 'missions'), where('coach_uid', '==', id));
    const missions = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let new_mission = doc.data();
      new_mission.uid = doc.id;
      missions.push(new_mission);
    });
    return missions;
  },

  getClientMissions: async (id) => {
    const q = query(
      collection(db, 'missions'),
      where('client_uid', '==', id),
      orderBy('created_at', 'asc')
    );
    const missions = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let new_mission = doc.data();
      new_mission.uid = doc.id;
      missions.push(new_mission);
    });
    return missions;
  },

  getClientManagingPartnerMissions: async (id) => {
    const q = query(
      collection(db, 'missions'),
      where('managing_partner_uids', 'array-contains', id),
      orderBy('created_at', 'asc')
    );
    const missions = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let new_mission = doc.data();
      new_mission.uid = doc.id;
      missions.push(new_mission);
    });
    return missions;
  },

  createMission: async (body) => {
    try {
      const id = uuidv4();
      await setDoc(doc(db, 'missions', id), {
        client_uid: body.client_uid,
        coach_uid: body.coach_uid,
        type: body.type,
        title: body.title,
        managing_partner_uids: body.managing_partenair_uids,
        status: 1,
        campaign_uids: [],
        created_at: Date.now(),
      });
      return id;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  createMissionTeamOrga: async (body) => {
    try {
      const idTeam = uuidv4();
      const idOrga = uuidv4();

      await setDoc(doc(db, 'missions', idTeam), {
        client_uid: body.client_uid,
        relation_with_orga: idOrga,
        coach_uid: body.coach_uid,
        type: 'team',
        relation_name: body.title + ' organisation',
        title: body.title,
        managing_partner_uids: body.managing_partenair_uids,
        status: 1,
        campaign_uids: [],
        created_at: Date.now(),
      });

      await setDoc(doc(db, 'missions', idOrga), {
        client_uid: body.client_uid,
        relation_with_team: idTeam,
        relation_name: body.title + ' team',
        coach_uid: body.coach_uid,
        type: 'organisation',
        title: body.title,
        status: 1,
        campaign_uids: [],
        created_at: Date.now(),
      });
      return true;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  createCampaign: async (body, teamId, orgaId) => {
    let id;
    let campaign = {
      status: 0,
      team_uids: {},
      title: body,
      type: 'team',
      created_at: Date.now(),
    };
    if (teamId && orgaId) {
      id = teamId;
      campaign.relation_with_orga = orgaId;
    } else {
      id = uuidv4();
    }
    try {
      await setDoc(doc(db, 'campaigns', id), campaign);
      return id;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  createCampaignOrga: async (body, teamId, orgaId) => {
    let id;
    let campaign = {
      status: 0,
      relations: {},
      title: body,
      created_at: Date.now(),
      type: 'organisation',
    };
    if (teamId && orgaId) {
      id = orgaId;
      campaign.relation_with_team = teamId;
    } else {
      id = uuidv4();
    }
    try {
      await setDoc(doc(db, 'campaigns', id), campaign);
      return id;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  createCampaignOrgaAndTeam: async (body) => {
    const orgaIdTmp = uuidv4();
    const teamIdTmp = uuidv4();
    const orgaId = await MISSIONS.createCampaignOrga(
      body,
      teamIdTmp,
      orgaIdTmp
    );
    const teamId = await MISSIONS.createCampaign(body, teamIdTmp, orgaIdTmp);
    return { orgaId, teamId };
  },

  updateMission: async (id, body) => {
    const missionRef = doc(db, 'missions', id);
    try {
      await updateDoc(missionRef, body);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  addCampaignToMission: async (missionId, campaignId) => {
    try {
      await updateDoc(doc(db, 'missions', missionId), {
        campaign_uids: arrayUnion(campaignId),
      });
      return true;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  addSurveyToCampaign: async (id, campaignId, surveyId) => {
    try {
      const docRefMission = await getDoc(doc(db, 'missions', id));
      const campaigns = await docRefMission.data().campaigns;
      const index = campaigns.findIndex((x) => x.id === campaignId);
      campaigns[index].surveys_uid.push(surveyId);
      await updateDoc(doc(db, 'missions', id), {
        campaigns: campaigns,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  removeTeamFromMission: async (id, body) => {
    try {
      await updateDoc(doc(db, 'missions', id), {
        teams: arrayRemove(body),
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  deleteUser: async (id) => {
    try {
      await deleteDoc(doc(db, 'missions', id));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

export { MISSIONS };
