/* eslint-disable no-unused-vars */
import { buildTooltipOrga, hideTooltip, hideAllTippies } from '../tooltips';
// import popper from 'cytoscape-popper';

export const initCytoscapeOrga = (survey) => {
  // eslint-disable-next-line no-undef
  const cy = cytoscape({
    container: document.getElementById('cy-orga'),

    elements: survey.map.map_data,

    style: [
      {
        selector: 'node[name]',
        style: {
          content: 'data(name)',
          width: 10,
          height: 10,
          'text-valign': 'center',
          'text-halign': 'center',
          fontSize: '9px',
        },
      },

      {
        selector: 'edge',
        style: {
          width: 1,
          'curve-style': 'bezier',
          'target-arrow-shape': 'triangle',
        },
      },
      {
        selector: '[?highlighted]',
        style: {
          opacity: '1',
        },
      },
      {
        selector: '[!highlighted]',
        style: {
          opacity: '0.10',
        },
      },
    ],

    layout: {
      name: 'concentric',
      levelWidth: function (nodes) {
        return 100;
      },
      minNodeSpacing: 60,
      nodeDimensionsIncludeLabels: true,
      concentric: function (n) {
        return n.id() === 'j' ? 200 : 0;
      },
    },
  });

  cy.on('tap', function (e) {
    if (e.target === cy) {
      hideAllTippies(cy);
    }
  });

  let relation;

  survey.relation_with_team ? (relation = true) : (relation = false);

  cy.edges().forEach(function (edge) {
    buildTooltipOrga(edge, survey.map.lang, relation);
  });

  let focus = false;
  let edge;

  if (survey.relation_with_team) {
    cy.edges().on('mouseover', function (event) {
      let edgeTmp = event.target;
      edge = edgeTmp;
      focus = true;

      if (edgeTmp.data('highlighted')) {
        var width = edge.numericStyle('width');
        edgeTmp.style('width', width * 2);
        setTimeout(() => {
          if (focus && edgeTmp === edge) {
            edgeTmp.data('tippy').show();
            event.cy.nodes().not(edgeTmp).forEach(hideTooltip);
          }
        }, 1000);
      }
    });

    cy.edges().on('mouseout', function (e) {
      focus = false;
      edge = e.target;
      if (edge.data('highlighted')) {
        var width = edge.numericStyle('width');
        edge.style('width', width / 2);
        // edge.data('tippy').hide();
      }
    });
  } else {
    cy.edges().on('mouseover', function (event) {
      let edge = event.target;

      if (edge.data('highlighted')) {
        var width = edge.numericStyle('width');
        edge.style('width', width * 2);
      }

      event.cy.nodes().not(edge).forEach(hideTooltip);
    });

    cy.edges().on('mouseout', function (e) {
      let edge = e.target;
      if (edge.data('highlighted')) {
        var width = edge.numericStyle('width');
        edge.style('width', width / 2);
        edge.data('tippy').hide();
      }
    });

    cy.edges().on('click', function (event) {
      let edge = event.target;
      if (edge.data('highlighted')) {
        edge.data('tippy').show();
      }
    });
  }

  cy.edges().forEach(function (edge) {
    let dimensions = edge.data('dimensions');
    let type = edge.data('externe');
    if (type) {
      edge.style('target-arrow-shape', 'none');
      edge.style('line-style', 'dashed');
    }
    const result = dimensions.reduce(function (prev, curr) {
      return prev.value < curr.value ? prev : curr;
    });

    switch (result.key) {
      case 'meaning':
        edge.style('line-color', '#4e8cff');
        edge.style('target-arrow-color', '#4e8cff');
        break;
      case 'energy':
        edge.style('line-color', '#fddc70');
        edge.style('target-arrow-color', '#fddc70');
        break;
      case 'time':
        edge.style('line-color', '#9177ff');
        edge.style('target-arrow-color', '#9177ff');

        break;
      case 'space':
        edge.style('line-color', '#14c1b2');
        edge.style('target-arrow-color', '#14c1b2');
        break;
      case 'emotion':
        edge.style('line-color', '#F16C6C');
        edge.style('target-arrow-color', '#F16C6C');
        break;
    }
  });

  return cy;
};
