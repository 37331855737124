import { initializeApp } from 'firebase/app';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { AuthProvider, isUserConnected, AuthContext } from './authentification';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

import { USERS } from './users';
import { CLIENTS } from './clients';
import { SURVEYS } from './surveys';
import { MISSIONS } from './missions';
import { CAMPAIGNS } from './campaigns';
import { TEAMS } from './teams';

console.log('Node Env', process.env.NODE_ENV);
console.log('Project ID', process.env.REACT_APP_PROJECT_ID);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const storage = getStorage(app, process.env.REACT_APP_STORAGE_APP);

// const functionss = getFunctions();

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, 'localhost', 9199);
}

export { app, db, storage };
export {
  AuthProvider,
  isUserConnected,
  MISSIONS,
  AuthContext,
  USERS,
  CLIENTS,
  // functionss,
  SURVEYS,
  CAMPAIGNS,
  TEAMS,
};
