import React from 'react';
import img2 from '../assets/img/methode_pro_visu 1.png';
import logo from '../assets/img/pro_logo_brand 1.png';

export default function LogLayout({ children }) {
  return (
    <div className="h-screen w-full bg-greenBaaS flex justify-center items-center">
      <div className="h-[600px] w-[1000px] bg-white flex rounded-xl shadow-2xl">
        <div className="w-1/2 h-full p-4 flex flex-col justify-start items-center">
          {children}
        </div>
        <div className="w-1/2 h-full bg-greyBaaS flex flex-col items-center justify-start rounded-xl">
          <img className="w-48 mb-16 mt-8" src={logo} />
          <img className="w-[400px]" src={img2} />
        </div>
      </div>
    </div>
  );
}
