import React from 'react';

export default function TeamList({ team, setSelectedTeam, selectedTeam }) {
  const handleChange = (select) => {
    setSelectedTeam(select);
  };

  const checked = () => {
    if (selectedTeam && selectedTeam.uid === team.uid) {
      return (
        <button
          onClick={() => handleChange('')}
          className="bg-greenBaaS border w-full py-1 px-2 rounded-2xl text-white shadow"
        >
          {team.team}
        </button>
      );
    } else if (
      !selectedTeam ||
      selectedTeam === '' ||
      selectedTeam.uid === team.uid
    ) {
      return (
        <button
          onClick={() => handleChange(team)}
          className="border w-full py-1 px-2 rounded-2xl text-black shadow"
        >
          {team.team}
        </button>
      );
    } else {
      return (
        <button
          onClick={() => handleChange(team)}
          className=" border w-full py-1 px-2 rounded-2xl text-black shadow"
        >
          {team.team}
        </button>
      );
    }
  };

  return (
    <>
      <div className=" w-full flex items-center justify-center">
        {checked()}
      </div>
    </>
  );
}
