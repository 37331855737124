// import { Form, Table, ProgressBar } from 'react-bootstrap';

/**
 * Build the tooltip for an edge
 * @param {*} edge Edge from cytoscape component
 * @returns Tooltip for the specifed edge
 */

export const RelationTooltip = (edge, lang) => {
  var dimData = edge.data('dimensions');

  let sourceName = edge.source().data('name');
  let targetName = edge.target().data('name');

  const dimLines = [];

  const displayDimensionKey = (key) => {
    console.log();
    let dimension = '';
    switch (key) {
      case 'meaning':
        lang === 'fr' ? (dimension = 'Sens') : (dimension = 'Meaning');
        break;
      case 'energy':
        lang === 'fr' ? (dimension = 'Energie') : (dimension = 'Energy');
        break;
      case 'time':
        lang === 'fr' ? (dimension = 'Temps') : (dimension = 'Time');
        break;
      case 'space':
        lang === 'fr' ? (dimension = 'Espace') : (dimension = 'Space');
        break;
      case 'emotion':
        lang === 'fr' ? (dimension = 'Emotion') : (dimension = 'Emotion');
        break;
    }
    return dimension;
  };

  dimData.forEach(function (dim) {
    let bgColor;
    switch (dim.key) {
      case 'meaning':
        bgColor = '#4e8cff';
        break;
      case 'energy':
        bgColor = '#fddc70';
        break;
      case 'time':
        bgColor = '#9177ff';
        break;
      case 'space':
        bgColor = '#14c1b2';
        break;
      case 'emotion':
        bgColor = '#f16c6c';
        break;
    }
    const barValue = dim.value * 10;
    dimLines.push(
      <li className="flex justify-between items-center" key={dim.key}>
        <div className="w-1/4">
          <p className="text-black text-capitalize ">
            {displayDimensionKey(dim.key)}
          </p>
        </div>

        <div className="h-2 w-2/4 border border-black rounded-2xl">
          <div
            style={{ width: barValue + '%', backgroundColor: bgColor }}
            className="h-full rounded-2xl"
          ></div>
        </div>

        <p className="text-right w-10   text-black">{dim.value}</p>
      </li>
    );
  });

  return (
    <div className="border bg-greyBaaS p-4 shadow rounded-2xl w-[300px]">
      <p className="mb-3 text-center">
        Footage <span className="text-greenBaaS font-bold">{targetName} </span>
        by <span className="text-greenBaaS font-bold">{sourceName}</span>
      </p>
      <div>
        <ul className="flex flex-col">{dimLines}</ul>
      </div>
    </div>
  );
};

export default RelationTooltip;
