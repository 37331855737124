import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../firebase';
import { Link } from 'react-router-dom';
import Layout from '../layout/Layout';
import { useTranslation } from 'react-i18next';

import { MISSIONS } from '../firebase';
import ListMission from '../components/mission/ListMission';

export default function ViewAllMissions() {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const [missions, setMissions] = useState([]);
  console.log(currentUser);
  useEffect(async () => {
    let missionTmp;

    if (currentUser.profile.isCoach()) {
      missionTmp = await MISSIONS.getCoachMissions(currentUser.user.uid);
    } else if (currentUser.profile.isAdmin()) {
      missionTmp = await MISSIONS.getClientMissions(
        currentUser.profile.admin_client_uid
      );
    } else if (currentUser.profile.isManagingPartner()) {
      missionTmp = await MISSIONS.getClientManagingPartnerMissions(
        currentUser.profile.managing_partner_client_uid
      );
    }
    setMissions(missionTmp);
  }, []);

  const newMissionButton = () => {
    if (currentUser.profile.isCoach()) {
      return (
        <div className="flex justify-center mt-16">
          <Link to={'new-mission'} className="button">
            {t('newMission')}
          </Link>
        </div>
      );
    }
  };

  return (
    <Layout>
      <h1 className="text-[38px] font-bold text-greenBaaS mt-10 mb-10">
        {t('welcome')}
      </h1>
      <ListMission missions={missions} />
      {newMissionButton()}
    </Layout>
  );
}
