import { Suspense } from 'react';
import { AuthProvider, isUserConnected } from './firebase';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import './App.css';

// components
import Login from './pages/Login';
import Register from './pages/Register';
import ForgottenPassword from './pages/ForgottenPassword';
import UpdatePassword from './pages/UpdatePassword';
import CreateCampaign from './pages/CreateCampaign';
import ViewAllMissions from './pages/ViewAllMissions';
import Survey from './pages/Survey';
import CollabSurveys from './pages/CollabSurveys';
import MissionDetails from './pages/MissionDetails';
import TeamConfigurationCampaign from './pages/TeamConfigurationCampaign';
import Campaign from './pages/Campaign';
import ViewMap from './pages/ViewMap';
import ViewOrgaMap from './pages/ViewOrgaMap';
import Home from './pages/Home';
import CreateMission from './pages/CreateMission';
import Congrats from './pages/Congrats';
import NotFound from './pages/NotFound';
import Welcome from './pages/Welcome';
import Profile from './pages/Profile';
import Clients from './pages/Clients';

import Reset from './pages/Reset';
import NewPassword from './pages/NewPassword';

function App() {
  const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const state = isUserConnected();

    return state.isAuthenticated ? (
      <RouteComponent {...rest} />
    ) : (
      <Navigate to="/login" />
    );
  };

  return (
    <Suspense fallback="...loading">
      <div className="App">
        <AuthProvider>
          <Router>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route
                exact
                path="/welcome"
                element={<PrivateRoute component={Welcome} />}
              />
              <Route
                exact
                path="/profile"
                element={<PrivateRoute component={Profile} />}
              />
              {/* VUE SUR LES MISSIONS DU CLIENT */}
              <Route
                exact
                path="/missions"
                element={<PrivateRoute component={ViewAllMissions} />}
              />
              {/* VUE SUR CLIENTS */}

              <Route
                exact
                path="/clients"
                element={<PrivateRoute component={Clients} />}
              />
              {/* VUE SUR MISSION */}
              <Route
                path="/missions/:mission_id"
                element={<PrivateRoute component={MissionDetails} />}
              />

              {/* CLICK BOUTON CRÉER MISSION ENVOI SUR CREATION MISSION*/}
              <Route
                exact
                path="/missions/new-mission"
                element={<PrivateRoute component={CreateMission} />}
              />
              {/* APRES CRÉATION DE LA CAMPAGNE ACCÈS A CONFIGURATION A AJOUT DE LA TEAM */}
              <Route
                path="/missions_coach/:mission_id/:campaign_id/team-configuration-campaign"
                element={<PrivateRoute component={TeamConfigurationCampaign} />}
              />
              <Route
                exact
                path="/surveys"
                element={<PrivateRoute component={CollabSurveys} />}
              />
              <Route
                path="/congrats"
                element={<PrivateRoute component={Congrats} />}
              />
              <Route
                exact
                path="/surveys/:survey_id"
                element={<PrivateRoute component={Survey} />}
              />
              <Route
                exact
                path="/campaign/:campaign_id"
                element={<PrivateRoute component={Campaign} />}
              />
              <Route
                exact
                path="/map_team/:survey_id"
                element={<PrivateRoute component={ViewMap} />}
              />
              <Route
                exact
                path="/map_orga/:campaign_id"
                element={<PrivateRoute component={ViewOrgaMap} />}
              />
              {/* PAGE MISSION, CLICK SUR DETAILS AJOUTER CAMPAGNE */}
              <Route
                path="/missions/:mission_id/create-campaign"
                element={<PrivateRoute component={CreateCampaign} />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgotten" element={<ForgottenPassword />} />
              <Route path="/update-password" element={<UpdatePassword />} />
              <Route path="/reset" element={<Reset />} />
              <Route
                exact
                path="/create-new-password"
                element={<PrivateRoute component={NewPassword} />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </AuthProvider>
      </div>
    </Suspense>
  );
}

export default App;
