import React, { useContext, useState } from 'react';
import { AuthContext } from '../firebase';
import { isPasswordStrong } from '../helpers/password_helpers';

import Layout from '../layout/Layout';
import { useTranslation } from 'react-i18next';

export default function Profile() {
  const { t } = useTranslation();
  const [password, setPassword] = useState({
    old: '',
    new: '',
  });

  const [error, setError] = useState('');

  const { changePassword, logout } = useContext(AuthContext);

  const changePasswordHandler = async (e) => {
    e.preventDefault();

    if (!isPasswordStrong(password.new)) {
      setError(`
        ${t('passwordLenght')}
        ${t('passwordUppercase')}
        ${t('passwordLowercase')}
        ${t('passwordDigit')}
        ${t('passwordSpecial')}
        `);
    } else {
      let success = await changePassword(password.new);
      if (success) {
        logout();
      } else {
        setError('An error occured during password change');
      }
    }
  };

  return (
    <>
      <Layout>
        <h1 className="text-[36px] text-greenBaaS text-bold mt-4 mb-10">
          {t('profile')}
        </h1>
        <form className="w-2/3 flex flex-col">
          <input
            name="old_password"
            placeholder="Ancien mot de passe"
            autoComplete="off"
            type="password"
            onChange={(e) => setPassword({ ...password, old: e.target.value })}
            value={password.old}
            className=" bg-white w-1/3 py-4 px-2 my-10   border shadow-sm  rounded-xl"
          />

          <input
            name="password"
            placeholder="Nouveau mot de passe"
            autoComplete="off"
            type="password"
            onChange={(e) => setPassword({ ...password, new: e.target.value })}
            value={password.new}
            className=" bg-white w-1/3 py-4 px-2 mb-10 border shadow-sm  rounded-xl"
          />

          <button
            onClick={(e) => changePasswordHandler(e)}
            className="cursor-pointer px-3 py-2 w-48 text-center text-white bg-greenButtonBaaS hover:bg-greenBaaS rounded-[100px]"
          >
            {t('changePassword')}
          </button>
          <p className="text-xs text-red-600 mt-5 whitespace-pre-wrap">
            {error}
          </p>
        </form>
      </Layout>
    </>
  );
}
