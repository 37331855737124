import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, CAMPAIGNS, TEAMS } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ModifyTeamToCampaignModal from '../modals/ModifyTeamToCampaignModal';
import { useTranslation } from 'react-i18next';
import { MissionContext } from '../../hooks';

const handleRemoveTeam = async (campaignUid, teamUid) => {
  try {
    await CAMPAIGNS.removeTeamFromCampaign(campaignUid, teamUid);
  } catch (error) {
    console.log('Error occured when removing team from campaign', error);
  }
};

const displayName = (collab) => {
  const index = collab.indexOf('@');
  return collab.substr(0, index);
};

export default function TeamConfigurationRow({ teamUid, campaign }) {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const { mission } = useContext(MissionContext);
  const [collabInfos, setCollabInfos] = useState(null);
  const [team, setTeam] = useState(null);
  const [modifyTeam, setModifyTeam] = useState(false);

  useEffect(async () => {
    const teamTmp = await TEAMS.getTeam(teamUid);
    const functions = getFunctions();
    const fetchUserData = httpsCallable(functions, 'fetchUserData');
    const response = await fetchUserData({
      requesterUID: currentUser.user.uid,
      requestedClientUID: mission.client_uid,
      requestedUserUIDS: teamTmp.collab_uids,
    });
    setTeam(teamTmp);
    setCollabInfos(response.data);
  }, []);

  const buildCollabList = () => {
    if (collabInfos) {
      return collabInfos.map((collabInfo, index) => {
        return (
          <li
            className={
              team.managers_uid.includes(collabInfo.uid)
                ? 'my-1 font-bold'
                : 'my-1'
            }
            key={index}
          >
            {displayName(collabInfo.email)}
          </li>
        );
      });
    } else {
      return <p>Building collaborator list ...</p>;
    }
  };

  return (
    <>
      <ModifyTeamToCampaignModal
        setOpen={setModifyTeam}
        open={modifyTeam}
        collaborators={collabInfos}
        setCollaborators={setCollabInfos}
        team={team}
        campaign={campaign}
      />
      <div className="flex border border-black py-2">
        <div className="border-r-2 w-2/5  border-black  flex items-center justify-center">
          <p>{team && team.team}</p>
        </div>
        <ul className="border-r-2 w-1/5 border-black flex flex-col items-center justify-center">
          {buildCollabList()}
        </ul>
        <div className="border-black w-2/5 flex items-center justify-around flex-wrap">
          <button
            onClick={() => setModifyTeam(true)}
            className="button bg-blue-300 hover:bg-blue-600"
          >
            {t('modify')}
          </button>

          <button
            onClick={() => handleRemoveTeam(campaign.uid, teamUid)}
            className="button bg-red-300 hover:bg-red-600"
          >
            {t('delete')}
          </button>
        </div>
      </div>
    </>
  );
}
