import React from 'react';
import TeamRunningHeader from '../team/TeamRunningHeader';

export default function ArrayTeamConArrayTeamConfigurationCampaignfigurationMission({
  children,
}) {
  return (
    <div className="mb-8">
      <div className="border-2 border-black min-h-[100px] rounded">
        <TeamRunningHeader />
        {children}
      </div>
    </div>
  );
}
