import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import CampaignStatus from './CampaignStatus';
import CampaignAction from './CampaignAction';
import CampaignRunning from './CampaignRunning';
import CampaignConfiguration from './CampaignConfiguration';
import { onSnapshot, doc } from 'firebase/firestore';

const displayCampaignAction = (props) => {
  return (
    <div>
      <CampaignAction campaign={props} />
    </div>
  );
};

const surveyRows = (props) => {
  if (props && props.status === 0) {
    return <CampaignConfiguration campaign={props} />;
  } else {
    return <CampaignRunning campaign={props} />;
  }
};

export default function CampaignCard({ campaignUid }) {
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    const campaignSnapshot = onSnapshot(
      doc(db, 'campaigns', campaignUid),
      (doc) => {
        const campaignTmp = doc.data();
        campaignTmp.uid = doc.id;
        setCampaign(campaignTmp);
      }
    );

    return () => campaignSnapshot();
  }, []);

  return (
    <div className="mx-auto">
      <div className="flex items-center mb-4">
        <h1 className="text-[20px]   font-bold text-greenBaaS mr-8">
          {campaign && campaign.title}
        </h1>
        <CampaignStatus campaign={campaign} />
        {displayCampaignAction(campaign)}
      </div>
      <div>{surveyRows(campaign)}</div>
    </div>
  );
}
