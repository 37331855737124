import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CytoscapeOrga from '../components/CytoscapeOrga';
import { CAMPAIGNS } from '../firebase';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import MenuOrga from '../components/cytoscape/MenuOrga';

// import { mapGenerator } from '../helpers/mapGenerator';

export default function ViewMap() {
  const { t } = useTranslation();
  const params = useParams();
  const [campaign, setCampaign] = useState();

  useEffect(async () => {
    const campaignTmp = await CAMPAIGNS.getCampaign(params.campaign_id);
    setCampaign(campaignTmp);
  }, []);

  return (
    <>
      <Navbar />
      <div className="flex">
        <main className="w-full flex pb-0">
          <MenuOrga campaign={campaign} />
          <div className="flex justify-center w-full border ">
            {campaign && campaign.map ? (
              <div className="h-full mb-8 w-full flex justify-center items-center">
                <CytoscapeOrga
                  actionLoader={true}
                  campaign={campaign}
                  wordsCloud={campaign.map.word_cloud_url}
                />
              </div>
            ) : (
              <h2 className="text-[24px] text-black mb-10">{t('noCard')}</h2>
            )}
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
