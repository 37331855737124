import React from 'react';
import Layout from '../layout/Layout';
import methodPro from '../assets/img/methode_pro_visu 1.png';
import relationnel from '../assets/img/tissu_relationnel_visu 1.png';
import relation from '../assets/img/relation_visu 1.png';
import { useTranslation } from 'react-i18next';

export default function Home() {
  console.log(process.env.REACT_APP_PROJECT_ID);

  const { t } = useTranslation();
  return (
    <>
      <Layout>
        <div className="mt-[52px]">
          <div
            className="
          flex gap-8
          w-full h-1/3
          mb-[8px]
          "
          >
            <div className="w-7/12">
              <h1 className="text-[48px] font-normal mb-[16px]">
                {t('theMethod')}
              </h1>
              <p className="text-[18px] font-light">{t('text1')}</p>
            </div>
            <div
              className="
            w-5/12 
            "
            >
              <img className="h-full" src={methodPro} />
            </div>
          </div>

          <div className="w-full pr-[19px]">
            <h2 className="text-[36px] font-normal mb-[10px]">
              {t('glossary')}
            </h2>
            <div className="flex gap-12">
              <div className="w-1/2">
                <p className="text-[18px] font-bold text-greenBaaS">
                  {t('relationFabric')}
                </p>
                <div className="flex">
                  <p className="mr-6">{t('text2')}</p>
                  <img className="h-full" src={relationnel} />
                </div>
              </div>

              <div className="w-1/2">
                <p className="text-[18px] font-bold text-greenBaaS">
                  {t('relation')}
                </p>
                <div className="flex">
                  <p className="mr-6">{t('text3')}</p>
                  <img className="h-full" src={relation} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
