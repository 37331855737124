import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { TEAMS, AuthContext } from '../../firebase';
import { useTranslation } from 'react-i18next';

export default function SurveyItem({ survey }) {
  const { t } = useTranslation();
  const [team, setTeam] = useState(null);
  const { currentUser } = useContext(AuthContext);

  useEffect(async () => {
    const teamTmp = await TEAMS.getTeam(survey.team_uid);
    setTeam(teamTmp);
  }, []);

  const responseAvailable = () => {
    if (
      survey.collab_uids[currentUser.user.uid] &&
      survey.collab_uids[currentUser.user.uid] !== 'waiting'
    )
      return true;

    return false;
  };

  const formulaire = () => {
    if (!responseAvailable() && survey.status !== 2) {
      return (
        <Link
          to={`${survey.uid}`}
          className="px-3 py-1 text-white rounded-xl bg-purpleBaaS hover:bg-greenBaaS"
        >
          {t('go')}
        </Link>
      );
    } else {
      return survey.map ? (
        <Link
          to={`/map_team/${survey.uid}`}
          className="px-3 py-1 text-whote text-white rounded-xl bg-greenButtonBaaS hover:bg-greenBaaS"
        >
          {t('seeCard')}
        </Link>
      ) : (
        <p className="px-3 py-1 text-black">{t('noCardAvailable')}</p>
      );
    }
  };

  const statut = () => {
    if (!responseAvailable() && survey.status !== 2) {
      return <p className="text-red-700">{t('waiting')}</p>;
    } else if (survey.status === 2 && !responseAvailable()) {
      return <p className="text-red-700">{t('surveyClose')}</p>;
    } else {
      return <p className="text-greenBaaS">{t('answered')}</p>;
    }
  };

  return (
    <div className="h-[48px] flex  border-b border-black">
      <div className="h-full w-1/3 flex items-center justify-center">
        <p>{team ? team.team : 'loading'}</p>
      </div>
      <div className="w-2/3 flex justify-around h-full">
        <div className=" w-1/3 flex items-center justify-center">
          {statut()}
        </div>
        <div className="w-1/3 flex items-center justify-center">
          {formulaire()}
        </div>
      </div>
    </div>
  );
}
