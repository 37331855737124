import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cytoscape from '../components/Cytoscape';
import CytoscapeOrga from '../components/CytoscapeOrga';
import { SURVEYS, TEAMS, CAMPAIGNS } from '../firebase';
import Menu from '../components/cytoscape/Menu';
import MenuOrga from '../components/cytoscape/MenuOrga';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

export default function ViewMap() {
  const { t } = useTranslation();
  const params = useParams();
  const [survey, setSurvey] = useState();
  const [team, setTeam] = useState();
  const [relationWithOrga, setRelationWithOrga] = useState(false);
  const [campaign, setCampaign] = useState(null);

  useEffect(async () => {
    const surveyTmp = await SURVEYS.getSurvey(params.survey_id);
    const teamTmp = await TEAMS.getTeam(surveyTmp.team_uid);
    const responseUids = Object.values(surveyTmp.collab_uids);
    const collabUids = Object.keys(surveyTmp.collab_uids);
    const collabAvailable = [];

    for (let i = 0; i < responseUids.length; i++) {
      if (responseUids[i] !== null) {
        collabAvailable.push(collabUids[i]);
      }
    }

    const campaignTmp = await CAMPAIGNS.getCampaign(surveyTmp.campaign_uid);
    if (campaignTmp.relation_with_orga) {
      setRelationWithOrga(true);
      const response = await CAMPAIGNS.getCampaign(
        campaignTmp.relation_with_orga
      );
      setCampaign(response);
    }

    setTeam(teamTmp);
    setSurvey(surveyTmp);
  }, []);

  return (
    <>
      <Navbar />
      <div className="">
        <main className="w-full pb-0">
          <div className="flex">
            <Menu survey={survey} team={team} />
            <div className="flex justify-center w-full ">
              <div className="">
                {survey && survey.map ? (
                  <div className=" mb-8 w-[1200px] flex justify-center items-center">
                    <Cytoscape
                      team={team}
                      survey={survey}
                      wordsCloud={survey.words_cloud_url}
                    />
                  </div>
                ) : (
                  <h2 className="text-[24px] text-black mb-10">
                    {t('noCard')}
                  </h2>
                )}
              </div>
            </div>
          </div>
          {relationWithOrga && campaign && campaign.map && (
            <div className="flex w-full h-full">
              <MenuOrga campaign={campaign} />
              <div className="flex justify-center w-full ">
                <div className="">
                  <div className=" mb-8  w-[1200px] flex justify-center items-center">
                    <CytoscapeOrga
                      campaign={campaign}
                      actionLoader={false}
                      wordsCloud={campaign.map.word_cloud_url}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
      <Footer />
    </>
  );
}
