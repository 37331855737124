import { useState, useEffect, useContext } from 'react';
import { createUser, fetchUserData } from '../../firebase/cloud-function';
import { CLIENTS, AuthContext } from '../../firebase';

export default function DisplayAdmin({ client }) {
  const [admins, setAdmins] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [newMember, setNewMember] = useState('');

  useEffect(async () => {
    if (client) {
      const response = await fetchUserData({
        requesterUID: currentUser.user.uid,
        requestedClientUID: client.uid,
        requestedUserUIDS: client.admin_uids,
      });

      setAdmins(response.data);
    }
  }, []);

  const handlerClient = async (email) => {
    const clientTmp = await createUser({ email });
    return clientTmp.data;
  };

  const handleCreateNewMember = async (email) => {
    const response = await handlerClient(email);
    await CLIENTS.createAdmin(client.uid, response);
    setAdmins([
      ...admins.filter((m) => m.uid !== response),
      { email, uid: response },
    ]);
    setNewMember('');
  };

  const handleDeleteMember = async (userUid) => {
    await CLIENTS.deleteAdmin(client.uid, userUid);
    setAdmins([...admins.filter((m) => m.uid !== userUid)]);
  };

  return (
    <div className="p-2">
      <p className="border-b-2 py-4">{client.name}</p>
      {admins &&
        admins.map((m, index) => {
          return (
            <div className="flex justify-between mb-2" key={index}>
              <p>{m.email}</p>
              <button onClick={() => handleDeleteMember(m.uid)}>X</button>
            </div>
          );
        })}
      <div className="border bg-green-100 p-4">
        <p>Add Collaborator to this team</p>
        <input
          className="border-2 rounded p-2 mb-4 h-10"
          placeholder="Collab Email"
          type="text"
          value={newMember}
          onChange={(e) => setNewMember(e.target.value)}
        />
        <button
          onClick={() => handleCreateNewMember(newMember)}
          className="button w-full"
        >
          Add
        </button>
      </div>
    </div>
  );
}
