import { db } from './index';
import {
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  limit,
} from 'firebase/firestore';

import { UserProfile } from '../model/userModel';

const USERS = {
  getUser: async (id) => {
    const docRef = await getDoc(doc(db, 'users', id));
    return docRef.data();
  },

  createCoach: async (user) => {
    console.debug('creating account for user', user);
    try {
      await setDoc(doc(db, 'coaches', user.uid), {
        created_at: Date.now(),
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  loadUserProfile: async (user_uid) => {
    let userData = new UserProfile();
    const coachDoc = await getDoc(doc(db, 'coaches', user_uid));
    userData.setCoach(coachDoc.exists());
    const clientColl = collection(db, 'clients');
    // check if user is a client admin
    let q = query(
      clientColl,
      where('admin_uids', 'array-contains', user_uid),
      limit(1)
    );

    let querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      userData.setAdminClientUId(querySnapshot.docs[0].id);
    }

    q = query(
      clientColl,
      where('managing_partner_uids', 'array-contains', user_uid),
      limit(1)
    );

    querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      userData.setManagingPartner(user_uid);
    }

    // check if user is a client admin
    q = query(
      clientColl,
      where('collab_uids', 'array-contains', user_uid),
      limit(1)
    );

    querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      userData.setCollabClientUId(querySnapshot.docs[0].id);
    }

    return userData;
  },

  getUsers: async () => {
    try {
      const q = query(
        collection(db, 'users'),
        where('roles', 'array-contains', 'collaborator')
      );
      const querySnapshot = await getDocs(q);
      const userList = [];
      querySnapshot.forEach((doc) => {
        userList.push(doc.data());
      });
      return userList;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  updateUser: async (id, body) => {
    const userRef = doc(db, 'user_data', id);
    try {
      await updateDoc(userRef, body);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  deleteUser: async (id) => {
    try {
      await deleteDoc(doc(db, 'user_data', id));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

export { USERS };
