import React from 'react';
import Layout from '../layout/Layout';
import { useEffect, useContext, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../firebase';

import { isPasswordStrong } from '../helpers/password_helpers';
import { useTranslation } from 'react-i18next';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Reset() {
  const { t } = useTranslation();
  const query = useQuery();
  const [actionCode, setActionCode] = useState(null);
  const [error, setError] = useState('');
  const [wrongPasswordError, setWrongPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const { verifyPasswordResetCodeHandler, confirmPasswordResetHandler } =
    useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(async () => {
    // Get the action to complete.
    const mode = query.get('mode');
    // Get the one-time code from the query parameter.

    const actionCode = query.get('oobCode');
    console.log(actionCode);
    // (Optional) Get the continue URL from the query parameter if available.
    //const continueUrl = query.get('continueUrl');
    // (Optional) Get the language code if available.
    //const lang = query.get('lang') || 'en';

    setActionCode(actionCode);

    switch (mode) {
      case 'resetPassword':
        {
          // Display reset password handler and UI.
          let success = await verifyPasswordResetCodeHandler(actionCode);
          if (success) {
            setError(null);
          } else {
            setError('It seems there is a problem with your reset link');
          }
        }

        break;
      case 'recoverEmail':
        break;
      case 'verifyEmail':
        break;
      default:
      // Error: invalid mode.
    }
  }, []);

  const changePasswordHandler = async (e) => {
    e.preventDefault;

    if (!isPasswordStrong(newPassword)) {
      console.log('Password is not strong enough');

      setWrongPasswordError(`
        ${t('passwordLenght')}
        ${t('passwordUppercase')}
        ${t('passwordLowercase')}
        ${t('passwordDigit')}
        ${t('passwordSpecial')}
          `);
    } else {
      let success = await confirmPasswordResetHandler(actionCode, newPassword);
      if (success) {
        console.log('Change password successful');
        navigate('/login');
      } else {
        setWrongPasswordError('An error occured during password change');
      }
    }
  };

  const buildPageLayout = () => {
    if (error !== null) {
      return (
        <div>
          <h1 className="text-[38px] font-bold mt-10 mb-5">
            {t('resettingPassword')}
          </h1>
          <p className="text-lg h-8 mt-5 whitespace-pre-wrap">{error}</p>
        </div>
      );
    } else {
      return (
        <div>
          <h1 className="text-[38px] font-bold text-greenBaaS mt-10 mb-5">
            {t('resetPassword')}
          </h1>
          <form className="w-2/3 flex flex-col">
            <input
              name="password"
              placeholder="Nouveau mot de passe"
              autoComplete="off"
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              className=" bg-white w-1/3 py-4 px-2 mb-10 border shadow-sm  rounded-xl"
            />

            <p
              onClick={changePasswordHandler}
              className="cursor-pointer w-1/6 p-2  text-center text-white bg-greenButtonBaaS hover:bg-greenBaaS rounded-[100px]"
            >
              {t('resetPassword')}
            </p>
            <p className="text-xs h-8 text-red-600 mt-5 whitespace-pre-wrap">
              {wrongPasswordError}
            </p>
          </form>
        </div>
      );
    }
  };

  return (
    <>
      <Layout>{buildPageLayout()}</Layout>
    </>
  );
}
