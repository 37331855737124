/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useContext, useState } from 'react';

import Layout from '../layout/Layout';
import { AuthContext, db } from '../firebase';
import ListSurvey from '../components/survey/ListSurvey';
import { useTranslation } from 'react-i18next';
import { collection, query, onSnapshot, orderBy } from 'firebase/firestore';

export default function CollabSurveys() {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const [userSurveys, setUserSurveys] = useState([]);

  useEffect(async () => {
    // const surveys = await SURVEYS.getCollabSurvey(currentUser.user.uid);
    // setUserSurveys(surveys);

    const q = query(
      collection(db, 'surveys'),
      orderBy(`collab_uids.${currentUser.user.uid}`)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const surveyTmp = [];
      querySnapshot.forEach((doc) => {
        const survey = doc.data();
        survey.uid = doc.id;
        surveyTmp.push(survey);
      });

      setUserSurveys(surveyTmp);
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <Layout>
        <h1 className="text-[38px] font-bold text-greenBaaS mt-10 mb-10">
          {t('mySurveys')}
        </h1>

        <ListSurvey surveys={userSurveys} />
      </Layout>
    </>
  );
}
