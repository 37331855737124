import React, { useState, useEffect } from 'react';

export default function Modal({ open, children, setOpen }) {
  const [handlePositionDiv, setHandlePositionDiv] = useState(0);
  // const [divPosition] = useState(0);

  const handleModal = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      setOpen(false);
    }
  };

  // useEffect(() => {
  //   if (document.querySelector('#div-modal'))
  //     // setdivPosition(document.querySelector('#div-modal').clientHeight);
  //     console.log('coucou');
  // }, [children]);

  useEffect(() => {
    if (open) {
      return document.body.classList.add('overflow-hidden');
    }
    document.body.classList.remove('overflow-hidden');
  }, [open]);

  window.addEventListener('scroll', () => {
    if (window.pageYOffset) {
      if (window.pageYOffset > 75) {
        setHandlePositionDiv(window.pageYOffset - 75);
        return;
      }
      setHandlePositionDiv(0);
    }
  });

  const styles1 = {
    position: 'absolute',
    top: `${handlePositionDiv}px`,
  };

  // const styles2 = {
  //   position: 'absolute',
  //   top: `${divPosition}px`,
  // };

  const showModal = () => {
    if (open) {
      return (
        <div
          onClick={(e) => handleModal(e)}
          style={styles1}
          className={`absolute h-screen w-full left-0 bg-blue-400 bg-opacity-30 z-10 flex items-center justify-center`}
        >
          <div
            // style={styles2}
            id="div-modal"
            className={`w-[500px] overflow-scroll max-h-[500px]  p-8 text-center rounded-xl shadow-xl  bg-white z-90`}
          >
            <div>{children}</div>
          </div>
        </div>
      );
    }
  };

  return <>{showModal()}</>;
}
