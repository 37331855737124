import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TeamConfigurationHeader() {
  const { t } = useTranslation();
  return (
    <div className="h-[48px] flex bg-greyBaaS border-b-2 border-black">
      <div className="border-r-2 w-1/5  border-black  flex items-center justify-center">
        <p>{t('team')}</p>
      </div>
      <div className="border-r-2 w-1/5  border-black  flex items-center justify-center">
        <p>{t('targetTeam')}</p>
      </div>

      <div className="border-r-2 w-2/5 border-black flex items-center justify-center">
        <p>{t('collaborators')}</p>
      </div>
      <div className="border-black w-2/5 flex items-center justify-center">
        <p>Actions</p>
      </div>
    </div>
  );
}
