import React, { useState, useEffect } from 'react';

function getMatches(response, searchString) {
  return response.filter(
    (x) => x.email.search(new RegExp(searchString, 'i')) > -1
  );
}

export const SearchDropdown = ({
  collaborators,
  selectionEvaluators,
  setSelectionEvaluators,
}) => {
  const [searchString, setSearchString] = useState('');
  const [matches, setMatches] = useState([]); // load matches into state
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  useEffect(() => {
    if (collaborators && searchString.length > 1) {
      setMatches(getMatches(collaborators, searchString));
      console.log('Matches updated');
    }
  }, [collaborators, searchString, setMatches, getMatches]);

  // onChange for input field
  const handleChange = (e) => {
    setSearchString(e.target.value);
    setSelectedItem('');
    setSelectedItemIndex(0); // reset to preserve keyboard navigation
    setMatches([]); // reset 'matches' array
  };

  useKeyNavigation(
    matches,
    selectedItemIndex,
    setSelectedItemIndex,
    setSelectedItem,
    selectedItem
  );

  // Display "matches" array
  const displayMatches = () =>
    // double check matches is valid for map()
    matches && matches.length > 0
      ? matches.map((m, i) => (
          <li
            onMouseEnter={() => {
              setSelectedItemIndex(i + 1);
            }}
            key={i}
            className={
              selectedItemIndex - 1 === i
                ? 'cursor-pointer text-green-500 bg-greyBaaS'
                : 'cursor-pointer'
            }
            onClick={() => {
              setSelectedItem(m);
              setSelectionEvaluators((selectionEvaluators) => {
                if (!selectionEvaluators.includes(m.uid)) {
                  return [...selectionEvaluators, m.uid];
                }
                return selectionEvaluators.filter((t) => t !== m.uid);
              });
            }}
          >
            {m.email}{' '}
            {selectionEvaluators.includes(m.uid) && (
              <span className="ml-10">X</span>
            )}
          </li>
        ))
      : '';

  return (
    <div className="border p-2 rounded mt-4">
      <input
        type="text"
        value={selectedItem.email || searchString}
        className="border w-full rounded p-2"
        autoComplete="off"
        placeholder="Type email name"
        onChange={handleChange}
      />

      {matches && matches.length > 0 && (
        <ul className="search-results border p-4 rounded mt-2 max-h-[200px] overflow-scroll">
          {displayMatches()}
        </ul>
      )}
    </div>
  );
};

function useKeyNavigation(
  matches,
  selectedItemIndex,
  setSelectedItemIndex,
  setSelectedItem
) {
  useEffect(() => {
    if (matches && matches.length > 0) {
      let matchCount = matches.length;

      const handleKeyEvent = (e) => {
        // 38: Up Arrow Key
        if (e.keyCode === 38 || e.which === 38) {
          if (selectedItemIndex > 1 && selectedItemIndex <= matchCount) {
            setSelectedItemIndex(selectedItemIndex);
            setSelectedItem(matches[selectedItemIndex - 2]);
            setSelectedItemIndex((c) => c - 1);
          } else {
            setSelectedItemIndex(matchCount);
            setSelectedItem(matches[matchCount - 1]);
          }
        }
        // 40: DownArrow KEY
        if (e.keyCode === 40 || e.which === 40) {
          if (selectedItemIndex < matchCount) {
            setSelectedItemIndex(selectedItemIndex);
            setSelectedItem(matches[selectedItemIndex]);
          } else {
            setSelectedItemIndex(0);
            setSelectedItem(matches[0]);
          }
          setSelectedItemIndex((c) => c + 1);
        }
        // ENTER KEY
        if (e.keyCode === 13 || e.which === 13) {
          e.preventDefault();
          // pending selectedItemIndex update from arrows causes it to add 1 extra when Enter is pressed.
          // The following is required to allow it to select the correctly highlighted option.
          let selectedIndex = selectedItemIndex - 1;
          if (selectedIndex === -1) selectedIndex = 0;
          setSelectedItem(matches[selectedIndex]);
        }
      };

      window.addEventListener('keydown', handleKeyEvent);
      return () => window.removeEventListener('keydown', handleKeyEvent);
    }
  }, [matches, selectedItemIndex, setSelectedItemIndex, setSelectedItem]);
}
