import { db } from './index';
import {
  query,
  where,
  collection,
  getDocs,
  orderBy,
  doc,
  getDoc,
  addDoc,
  documentId,
  updateDoc,
} from 'firebase/firestore';

const SURVEYS = {
  getSurvey: async (id) => {
    const docRef = await getDoc(doc(db, 'surveys', id));
    let newItem = docRef.data();
    newItem.uid = docRef.id;
    return newItem;
  },

  getSurveyWithTeamId: async (id) => {
    const q = query(collection(db, 'surveys'), where(`team_uid`, '==', id));
    const docRef = await getDoc(q);
    let newItem = docRef.data();
    newItem.uid = docRef.id;
    return newItem;
  },

  createSurvey: async (team, body) => {
    try {
      const collaborators = {};
      team.collaborators.forEach((collaborator) => {
        collaborators[collaborator] = null;
      });
      const docRef = await addDoc(collection(db, 'surveys'), {
        typeform_id: null,
        created_at: Date.now(),
        status: 1,
        team_uid: body.teamId,
        data_launch: Date.now(),
        collab_uids: collaborators,
        type: 'string',
      });
      return docRef.id;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  getCollabSurvey: async (userUID) => {
    console.debug('Searching surveys for user', userUID);

    // Smart way to get only documents that contains this user_uid
    // Document without user_uid in collaborators field are excluded from the results automatically
    // This avoids creating an index on each user
    const q = query(
      collection(db, 'surveys'),
      orderBy(`collab_uids.${userUID}`)
    );

    const querySnapshot = await getDocs(q);
    const surveys = [];
    querySnapshot.forEach((doc) => {
      const surveyTmp = doc.data();
      surveyTmp.uid = doc.id;
      surveys.push(surveyTmp);
    });
    return surveys;
  },

  getSurveysBulk: async (surveyUIDS) => {
    const q = query(
      collection(db, 'surveys'),
      where(documentId(), 'in', surveyUIDS)
    );
    const querySnapshot = await getDocs(q);
    const surveys = [];
    querySnapshot.forEach((doc) => {
      surveys.push(doc.data());
    });

    return surveys;
  },
  updateSurvey: async (id, body) => {
    const surveyRef = doc(db, 'surveys', id);

    try {
      await updateDoc(surveyRef, body);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  updateSurveyOrga: async (id, userId, typeFormId) => {
    const surveyRef = await getDoc(doc(db, 'surveys', id));
    let surveyTmp = surveyRef.data();
    surveyTmp.collab_uids[userId] = typeFormId;
    await updateDoc(doc(db, 'surveys', id), surveyTmp);
  },
};

export { SURVEYS };
