import React, { useState, useContext, useEffect } from 'react';
// import { getFunctions, httpsCallable } from 'firebase/functions';
// import { useParams } from 'react-router-dom';

import { CAMPAIGNS, CLIENTS, AuthContext } from '../../firebase';
import { MissionContext } from '../../hooks';
import Modal from '../../layout/Modal';
import { fetchUserData } from '../../firebase/cloud-function';
import { SearchDropdownModifTeam } from '../tools/SearchDropdownModifTeam';

export default function OrgaModifyTeamToCampaignModal({
  open,
  setOpen,
  collaborators,
  campaignUid,
  relation,
  setCollabInfos,
}) {
  const { mission } = useContext(MissionContext);
  const { currentUser } = useContext(AuthContext);
  const [allCollabs, setAllCollabs] = useState(null);

  useEffect(async () => {
    const collabUidsTmp = await CLIENTS.getCollaboratos(mission.client_uid);
    const response = await fetchUserData({
      requesterUID: currentUser.user.uid,
      requestedClientUID: mission.client_uid,
      requestedUserUIDS: collabUidsTmp,
    });
    setAllCollabs(response.data);
  }, []);

  const handleDelete = async (userId) => {
    await CAMPAIGNS.removeUserFromRelationsCampaign(
      campaignUid,
      `${relation.team_uid}-${relation.target_team_uid}`,
      userId
    );
    const collabTmp = collaborators.filter((t) => t.uid !== userId);
    setCollabInfos(collabTmp);
  };

  const teamList = () => {
    if (collaborators) {
      return collaborators.map((user, index) => {
        return (
          <div key={index} className="flex items-center justify-between mb-4">
            <p>{user.email}</p>{' '}
            <button
              onClick={() => handleDelete(user.uid)}
              className="text-white rounded-2xl py-1 px-4  bg-red-500 hover:bg-red-800"
            >
              supprimer
            </button>
          </div>
        );
      });
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="border rounded-2xl shadow p-4 mb-8">{teamList()}</div>
      <div className="border bg-green-100 p-4">
        <p>Add New Collab</p>

        <SearchDropdownModifTeam
          relation={relation}
          campaignUid={campaignUid}
          collaborators={allCollabs}
          selectionEvaluators={collaborators}
          setSelectionEvaluators={setCollabInfos}
        />
      </div>
    </Modal>
  );
}
