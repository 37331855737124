/* eslint-disable no-undef */
import RelationTooltipOrgaTeamOrga from './relation-tooltip-orga-team-orga';
import RelationTooltip from './relation-tooltip';
import ReactDOMServer from 'react-dom/server';
import tippy from 'tippy.js';

export function buildTooltipOrga(edge, lang, relation) {
  let ref = edge.popperRef(); // used only for positioning

  // unfortunately, a dummy element must be passed as tippy only accepts a dom element as the target
  // https://github.com/atomiks/tippyjs/issues/661
  let dummyDomEle = document.createElement('div');

  let t;
  relation
    ? (t = RelationTooltipOrgaTeamOrga(edge, lang))
    : (t = RelationTooltip(edge, lang));

  var tip = new tippy(dummyDomEle, {
    // tippy options:
    // mandatory:
    trigger: 'manual', // call show() and hide() yourself
    lazy: false, // needed for onCreate()

    onCreate: (instance) => {
      instance.popperInstance.reference = ref;
    }, // needed for `ref` positioning

    // your custom options follow:
    hideOnClick: true,
    offset: '0, -40',
    theme: 'translucent',
    content: ReactDOMServer.renderToString(t),
  });

  edge.data('tippy', tip);
}

export function hideTooltip(node) {
  var tippy = node.data('tippy');

  if (tippy != null) {
    tippy.hide();
  }
}

export function hideAllTippies(cy) {
  cy.nodes().forEach(hideTooltip);
}

export function buildTooltip(edge, lang) {
  let ref = edge.popperRef(); // used only for positioning

  // unfortunately, a dummy element must be passed as tippy only accepts a dom element as the target
  // https://github.com/atomiks/tippyjs/issues/661
  let dummyDomEle = document.createElement('div');

  let t = RelationTooltip(edge, lang);

  var tip = new tippy(dummyDomEle, {
    // tippy options:
    // mandatory:
    trigger: 'manual', // call show() and hide() yourself
    lazy: false, // needed for onCreate()

    onCreate: (instance) => {
      instance.popperInstance.reference = ref;
    }, // needed for `ref` positioning

    // your custom options follow:
    hideOnClick: true,
    theme: 'translucent',
    content: ReactDOMServer.renderToString(t),
  });

  edge.data('tippy', tip);
}
