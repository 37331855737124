import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/Layout';
import { useTranslation } from 'react-i18next';

export default function Campaign() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Layout>
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <p className="text-lg text-greenBaaS mb-10">{t('thanksTo')}</p>
          <button className="button" onClick={() => navigate(`/surveys`)}>
            {t('goBack')}
          </button>
        </div>
      </Layout>
    </>
  );
}
