import React from 'react';

const truncateName = (collab) => {
  const index = collab.indexOf('@');
  return collab.substr(0, index);
};

export default function DisplayCollaborators({
  handleCollab,
  collabInfos,
  team,
}) {
  const displayCollab = () => {
    if (collabInfos && team) {
      return collabInfos.map((t, index) => {
        return (
          <li
            key={index}
            className={
              team.managers_uid.includes(t.uid) ? 'my-1 font-bold' : 'my-1'
            }
          >
            {truncateName(t.email)}
          </li>
        );
      });
    }
  };

  return (
    <div
      className={
        handleCollab && collabInfos
          ? 'block  shadow rounded-2xl border bg-white absolute bottom-10 p-4'
          : 'hidden'
      }
    >
      <ul>{displayCollab()}</ul>
    </div>
  );
}
