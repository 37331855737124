/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../firebase';
import { useTranslation } from 'react-i18next';
import { initCytoscapeOrga } from '../helpers/cytoscape/init-cytoscape-orga';
import Loader from '../components/tools/Loader';
import { ViewOrgaContext } from '../hooks';
import WordCloud from '../components/cytoscape/WordCloud';
import Efforts from '../components/cytoscape/Efforts';

export default function CytoscapeOrga({ campaign, wordsCloud, actionLoader }) {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  let cy;
  const { setSelectedSurvey } = useContext(ViewOrgaContext);

  useEffect(() => {
    if (campaign.map) {
      cy = initCytoscapeOrga(campaign);
    }
  });

  const restoreVizu = () => {
    cy = initCytoscapeOrga(campaign);
  };

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  const loader = () => {
    if (loading && actionLoader) {
      return <Loader />;
    }
  };

  return (
    <>
      <div
        className={
          !actionLoader && loading ? 'hidden' : 'w-full h-full relative '
        }
      >
        <p className="text-2xl  text-center mt-10 mb-10">
          {t('visualizationOrga')} {campaign.title}
        </p>
        <div className="flex justify-center items-center  mx-40">
          {loader()}
          <div className={loading ? 'hidden' : 'block w-full'}>
            <button onClick={() => restoreVizu()} className="button mb-4">
              {t('seenByDefault')}{' '}
            </button>

            <div
              id="cy-orga"
              className="h-[796px] relative bg-slate-100  rounded-2xl shadow  mx-auto  mb-8"
            ></div>

            {wordsCloud ? <WordCloud wordsCloud={wordsCloud} /> : <></>}
            <Efforts efforts={campaign.map.efforts} />
          </div>
        </div>
      </div>
    </>
  );
}
