import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  lauchTeamCampaign,
  finishAllCampaignsTeam,
  lauchOrgaCampaign,
  finishAllCampaignsOrga,
  generateMapCampaignsOrga,
} from '../../firebase/cloud-function';
import { useTranslation } from 'react-i18next';
import { uploadFiles } from '../../helpers/upload-file';
import { CAMPAIGNS, AuthContext } from '../../firebase';
import DisplayPicture from '../modals/DisplayPicture';
import { downloadCsv } from '../../helpers/download-csv';
// import { Parser } from 'json2csv';
// import fileDownload from 'js-file-download';

export default function CampaignAction({ campaign }) {
  const { t } = useTranslation();
  const [file, setFile] = useState(false);
  const [handlePicture, setHandlePicture] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [launch, setLaunch] = useState(false);
  const [language, setLanguage] = useState(false);
  const [alert, setAlert] = useState(false);

  const formHandler = async (e, id) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    const url = await uploadFiles(file, id);
    console.log(url);
    await CAMPAIGNS.orgaAddicture(campaign, url);
    setFile(false);
  };

  useEffect(() => {
    if (language) {
      setAlert(false);
    }
  }, [language]);

  const launchCampaign = async () => {
    if (campaign.type === 'team') {
      if (!language) {
        setAlert('please, select a language');
        return;
      }
      setLaunch(true);
      const response = await lauchTeamCampaign({
        campaign: campaign,
        lang: language,
      });
      if (response || !response) setLaunch(false);
    } else {
      if (!language) {
        setAlert('please, select a language');
        return;
      }
      setLaunch(true);
      const response = await lauchOrgaCampaign({
        campaign: campaign,
        lang: language,
      });
      if (response) setLaunch(false);
    }
  };

  const generateMap = async () => {
    setLaunch(true);
    const response = await generateMapCampaignsOrga({ campaign });

    if (response) setLaunch(false);
  };

  const finishMission = async () => {
    if (campaign.type === 'team') {
      await finishAllCampaignsTeam({ campaign });
    } else {
      await finishAllCampaignsOrga({ campaign });
    }
  };

  const launchCampaignDisplay = () => {
    if (launch) {
      return (
        <div className="button cursor-not-allowed bg-green-200">
          Launch in progress
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          <button onClick={() => launchCampaign()} className="button mr-4">
            {t('launchCampaign')}
          </button>
          <div className="flex  justify-center items-center flex-col">
            <p className={alert ? 'text-red-500' : ''}>
              Selection of language:{' '}
            </p>
            <div className="flex">
              <button
                onClick={() => setLanguage('en')}
                className={
                  language === 'en'
                    ? 'bg-green-100 h-10 rounded shadow p-2 mr-4'
                    : 'h-10 rounded shadow p-2 mr-4'
                }
              >
                🏴󠁧󠁢󠁥󠁮󠁧󠁿 English 🏴󠁧󠁢󠁥󠁮󠁧󠁿
              </button>
              <button
                onClick={() => setLanguage('fr')}
                className={
                  language === 'fr'
                    ? 'bg-green-100 h-10 rounded shadow p-2 mr-4'
                    : 'h-10 rounded shadow p-2 mr-4'
                }
              >
                🇫🇷 French 🇫🇷
              </button>
            </div>
          </div>
        </div>
      );
    }
  };
  const generateMapDisplay = () => {
    if (launch) {
      return (
        <div className="button cursor-not-allowed bg-green-200">
          Map generation in progress
        </div>
      );
    } else {
      return (
        <button onClick={generateMap} className="button">
          {t('generateCard')}
        </button>
      );
    }
  };

  if (campaign && campaign.status === 0 && currentUser.profile.isCoach()) {
    return launchCampaignDisplay();
  } else if (
    campaign &&
    campaign.status === 1 &&
    currentUser.profile.isCoach()
  ) {
    return (
      <div className="flex w-full flex-wrap items-center gap-2">
        <button onClick={() => finishMission()} className="button">
          {t('stopCampaign')}
        </button>
        {campaign.type === 'organisation' && (
          <>
            {generateMapDisplay()}
            {/* <button
              onClick={() => generateMapCampaignsOrga({ campaign })}
              className="button"
            >
              {t('generateCard')}
            </button> */}
            {campaign.map && (
              <>
                <Link to={`/map_orga/${campaign.uid}`} className="button ">
                  {t('seeCard')}
                </Link>
                <button
                  className="button"
                  onClick={() => downloadCsv(campaign, campaign.title, true)}
                >
                  download CSV
                </button>
                <form
                  onSubmit={(e) => formHandler(e, campaign)}
                  className="flex items-center"
                >
                  <label
                    onMouseEnter={
                      campaign.map.word_cloud_url
                        ? () => setHandlePicture(true)
                        : () => setHandlePicture(false)
                    }
                    onMouseLeave={
                      campaign.map.word_cloud_url
                        ? () => setHandlePicture(false)
                        : () => setHandlePicture(false)
                    }
                  >
                    <p className="cursor-pointer text-black ">Image</p>
                    {currentUser.profile.isCoach() ? (
                      <input
                        onChange={() => setFile(true)}
                        type="file"
                        className="hidden"
                      />
                    ) : (
                      <></>
                    )}
                  </label>
                  <div className="relative">
                    <DisplayPicture
                      handleCollab={handlePicture}
                      wordsCloud={
                        campaign.map.word_cloud_url
                          ? campaign.map.word_cloud_url
                          : ''
                      }
                    />
                  </div>
                  {file ? (
                    <button
                      className="border text-sm px-1 py-1 bg-green-100"
                      type="submit"
                    >
                      send
                    </button>
                  ) : (
                    ''
                  )}
                </form>

                <p type="submit" className="mr-4">
                  {campaign.map.word_cloud_url ? '✅ ' : '⛔️'}
                </p>
              </>
            )}
          </>
        )}
      </div>
    );
  } else if (currentUser.profile.isCoach()) {
    return (
      <div className="flex items-center gap-4">
        {campaign && campaign.type === 'organisation' && (
          <Link to={`/map_orga/${campaign.uid}`} className="button ml-4 mr-10">
            {t('seeCard')}
          </Link>
        )}
        {campaign && campaign.map && (
          <>
            <button
              className="button"
              onClick={() => downloadCsv(campaign, campaign.title, true)}
            >
              download
            </button>
            <form
              onSubmit={(e) => formHandler(e, campaign)}
              className="flex items-center"
            >
              <label
                onMouseEnter={
                  campaign.map.word_cloud_url
                    ? () => setHandlePicture(true)
                    : () => setHandlePicture(false)
                }
                onMouseLeave={
                  campaign.map.word_cloud_url
                    ? () => setHandlePicture(false)
                    : () => setHandlePicture(false)
                }
              >
                <p className="cursor-pointer text-black ">Image</p>
                {currentUser.profile.isCoach() ? (
                  <input
                    onChange={() => setFile(true)}
                    type="file"
                    className="hidden"
                  />
                ) : (
                  <></>
                )}
              </label>
              <div className="relative">
                <DisplayPicture
                  handleCollab={handlePicture}
                  wordsCloud={
                    campaign.map.word_cloud_url
                      ? campaign.map.word_cloud_url
                      : ''
                  }
                />
              </div>
              {file ? (
                <button
                  className="border text-sm px-1 py-1 bg-green-100"
                  type="submit"
                >
                  send
                </button>
              ) : (
                ''
              )}
            </form>
            <p type="submit">{campaign.map.word_cloud_url ? '✅ ' : '⛔️'}</p>
          </>
        )}
      </div>
    );
  } else {
    return (
      <>
        {' '}
        {campaign && campaign.type === 'organisation' && campaign.map && (
          <Link to={`/map_orga/${campaign.uid}`} className="button ml-4 mr-10">
            {t('seeCard')}
          </Link>
        )}
      </>
    );
  }
}
